import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const BannerTypeList = () => {
    const [BannerTypeList, setBannerTypeList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const [selectedBannerId, setSelectedBannerId] = useState(null);
    const [BannertypeformData, setBannertypeformData] = useState({
        banner_type_name: '',
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await axios.get('https://folcon.pureinfant.in/admin/all-banner-types', config);
            const responseData = response.data;

            let bannerType = [];

            if (Array.isArray(responseData)) {
                bannerType = responseData;
            } else if (Array.isArray(responseData.data)) {
                bannerType = responseData.data;
            }

            setBannerTypeList(bannerType);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);

    };

    const currentBannerTypeList = BannerTypeList.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage);
    const pageCount = Math.ceil(BannerTypeList.length / itemsPerPage);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleEdit = async (BannertypeId) => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await axios.get(`https://folcon.pureinfant.in/admin/get-banner-type/${BannertypeId}`, config);
            const responseData = response.data;

            setBannertypeformData({
                banner_type_name: responseData.data.banner_type_name,
            });
            setSelectedBannerId(BannertypeId);
            openModal();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBannertypeformData({ ...BannertypeformData, [name]: value });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        try {
            if (!BannertypeformData.banner_type_name) {
                toast.error('Please fill in the form data');
                return;
            }

            const existingBannerType = BannerTypeList.find(bannerType => bannerType.banner_type_id === selectedBannerId);
            if (existingBannerType && existingBannerType.banner_type_name === BannertypeformData.banner_type_name) {
                toast.warning('No changes detected. Please enter a different banner type name.');
                return;
            }

            const updatePayload = {
                ...BannertypeformData,
                banner_type_id: selectedBannerId,
            };

            const updateResponse = await axios.post('https://folcon.pureinfant.in/admin/update-banner-type', updatePayload, config);

            // console.log(updateResponse);

            if (updateResponse.data.status === "success") {
                toast.success('Banner type name updated successfully!');
                fetchData();
                setBannertypeformData({
                    banner_type_name: '',
                });

            } else {
                toast.error('Banner type name update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating Banner type name:', error);
            toast.error('Banner type name update failed. Please try again.');
        }
        closeModal();
    };


    const header = (
        <div className="table-header">
            <div>
                <h3>Banner Type List</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                <button onClick={() => navigate('/admin/banner-type')} className="btn btn-primary">Create Banner Type</button>
            </div>
        </div>
    );

    const columns = [
        {
            field: 'id', header: 'S.No', body: (rowData, { rowIndex }) => (
                <div>{pageNumber * itemsPerPage + rowIndex + 1}</div>)
        },
        { field: 'banner_type_name', header: 'Banner Type Name' },
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" onClick={() => handleEdit(rowData.banner_type_id)}>Edit info</a>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Banner Type List</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Banner Type</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>
            </div>
            <section className="section">
                <ReactDataTable
                    data={currentBannerTypeList}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    header={header}
                    emptyMessage="No Banner Type records found"
                    columns={columns}
                />
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </section>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Banner Type Info"
                style={{
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '20px',
                        maxHeight: '100%',
                        overflowY: 'auto',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    },
                }}
            >
                <div className='container' style={{ width: '900px' }}>
                    <div className="row">
                        <div className="col">
                            <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Banner Type</h2>
                        </div>
                        <div className="col-auto"></div>
                        <button onClick={closeModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
                        </button>
                        <form className="row g-4" onSubmit={handleSubmit}>
                            <div className="col-md-6">
                                <label htmlFor="banner_type_name" className="form-label">
                                    Banner Type Name
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="banner_type_name"
                                    name="banner_type_name"
                                    value={BannertypeformData.banner_type_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <ToastContainer />
        </main>
    );
};

export default BannerTypeList;
