import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ReactDataTable = ({ data, globalFilter, header, emptyMessage, columns }) => {
    return (
        <DataTable
            value={data}
            globalFilter={globalFilter}
            header={header}
            emptyMessage="No Brands records found"
            className="react-data-table"
        >
            {columns.map((column, index) => (
                <Column
                    key={index}
                    field={column.field}
                    header={column.header}
                    body={column.body}
                    sortable={column.sortable}
                />
            ))}
        </DataTable>
    );
};

export default ReactDataTable;
