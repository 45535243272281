import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import ProductList from "../../pages/views/product/ProductList";
import ProductCreate from "../../pages/views/product/Productcreate";


const Product = () => {
    return (
        <div>
            <AdminHeader />
            <SideBar />
            <main className="main-wrap">

                <ProductList />
            </main>
            <AdminFooter />
        </div>


    );

}

export default Product;