import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import { Column } from "react-virtualized";

const Taxlist = () => {
    const navigate = useNavigate();
    const [taxList, setTaxList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedTaxId, setSelectedTaxId] = useState(null);
    const [taxFormData, setTaxFormData] = useState({
        name: '',
        percentage: '',
        label: '',
        is_active: 0
    });

    useEffect(() => {
        fetchData();
    }, []);

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await fetch('https://folcon.pureinfant.in/admin/all-taxes', config);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            const taxData = Array.isArray(responseData) ? responseData : responseData.data || [];
            setTaxList(taxData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const handleEdit = async (taxId) => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await axios.get(`https://folcon.pureinfant.in/admin/get-tax/${taxId}`, config);
            const responseData = response.data;

            setTaxFormData({
                name: responseData.data.name,
                percentage: responseData.data.percentage,
                label: responseData.data.label,
                is_active: responseData.data.is_active
            });
            setSelectedTaxId(taxId);
            openModal();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        try {
            if (!taxFormData.name || !taxFormData.label) {
                toast.error('Please Fill The Form Data');
                return;
            }

            const isDuplicate = taxList.some(tax => tax.name === taxFormData.name || tax.label === taxFormData.label);
            if (isDuplicate) {
                toast.error('Tax name or label already exists. Please choose a different one.');
                return;
            }

            const createPayload = {
                ...taxFormData,
                percentage: parseFloat(taxFormData.percentage),
                id: selectedTaxId,
            };

            const createResponse = await axios.post('https://folcon.pureinfant.in/admin/update-tax', createPayload, config);

            if (createResponse.data.status === "success") {
                toast.success('Tax updated successfully!');
                fetchData();
                setTaxFormData({
                    name: '',
                    percentage: '',
                    label: '',
                    is_active: 0,
                });
            } else {
                toast.error('Tax update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating tax:', error);
            toast.error('Tax update failed. Please try again.');
        }
        closeModal();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;
        if (name === 'name') {
            newValue = value.toUpperCase().replace(/[^A-Z\s]/g, '');
        }
        if (name === 'percentage') {
            newValue = value.replace(/[^0-9]/g, '');
        }
        setTaxFormData({ ...taxFormData, [name]: newValue });
    };

    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        setTaxFormData({
            ...taxFormData,
            is_active: isChecked ? 1 : 0
        });
    };

    const columns = [
        { field: 'id', header: 'ID' },
        { field: 'name', header: 'Tax Name' },
        { field: 'percentage', header: 'Percentage' },
        { field: 'label', header: 'Label' },
        {
            field: 'is_active', header: 'Status', body: (rowData) => (
                <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>
                    {rowData.is_active === 1 ? 'Active' : 'Inactive'}
                </span>
            )
        },
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.id)}>Edit info</a>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    const filteredTaxList = taxList.filter((tax) =>
        tax.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
        tax.label.toLowerCase().includes(globalFilter.toLowerCase())
    );

    const pageCount = Math.ceil(filteredTaxList.length / itemsPerPage);
    const currentTaxList = filteredTaxList.slice(
        pageNumber * itemsPerPage,
        (pageNumber + 1) * itemsPerPage
    ).map((tax, index) => ({
        ...tax,
        sno: pageNumber * itemsPerPage + index + 1,
    }));

    const startItem = pageNumber * itemsPerPage + 1;
    const endItem = Math.min((pageNumber + 1) * itemsPerPage, filteredTaxList.length);

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Tax List</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Tax</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>
            </div>

            <br />
            <div className="pagetitle">
                <div className="searchButtonCorner">
                    <button onClick={() => navigate('/admin/tax-create')} className="btn btn-primary">Create Tax</button>
                    <div className="search-bar">
                        <span className="p-input-icon-left">
                            <input
                                type="search"
                                value={globalFilter}
                                onChange={(e) => setGlobalFilter(e.target.value)}
                                placeholder="Search..."
                                className="custom-input"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <section className="section">
                <DataTable
                    data={currentTaxList}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    emptyMessage="No tax records found"
                    columns={columns}
                    className="react-data-table"
                >
                    {columns.map((col, i) => (
                        <Column key={i} field={col.field} header={col.header} body={col.body} />
                    ))}
                </DataTable>
                <div className="results-info">
                    Showing {startItem} - {endItem} of {filteredTaxList.length} results
                </div>
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </section>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Tax Info"
                style={{
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '20px',
                        overflowY: 'auto',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    },
                }}
            >
                <div className='container' style={{ width: '900px' }}>
                    <div className="row">
                        <div className="col">
                            <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Tax</h2>
                        </div>
                        <div className="col-auto"></div>
                        <button onClick={closeModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
                        </button>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={taxFormData.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="percentage" className="form-label">Percentage</label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="percentage"
                                    name="percentage"
                                    value={taxFormData.percentage}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="label" className="form-label">Tax Label</label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="label"
                                    name="label"
                                    value={taxFormData.label}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="toggle" className="form-label">Status</label>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="toggle"
                                        name="is_active"
                                        checked={taxFormData.is_active === 1}
                                        onChange={handleToggleChange}
                                    />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Update</button>
                        </form>
                    </div>
                </div>
            </Modal>
        </main>
    );
};

export default Taxlist;
