import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faCircleXmark,
  faTrashAlt,
  faPlus,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Modal from "react-modal";
import UploadImage from "../../../assets/images/upload.png";
import { useNavigate } from "react-router-dom";
import noImage from "../../../assets/images/noimage.jpg";
import ReactPaginate from "react-paginate";
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import { MediaList } from "../../../service/apiService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const AdsList = () => {
  const navigate = useNavigate();
  const [AdsList, SetAdsList] = useState([]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [selectedColorCode, setSelectedColorCode] = useState("#000000");
  const [isColorPickerDisabled, setIsColorPickerDisabled] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [responsiveImage, setresponsiveImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages_two, setSelectedImages_two] = useState([]);
  const [selectedadsId, setSelectedadsId] = useState(null);
  const [imag, setImag] = useState(null);
  const [responsiveimag, setresponsiveImag] = useState(null);
  //const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const AppUrl = "https://folcon.pureinfant.in/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  const [responsiveUrl, setresponsiveUrl] = useState([]);
  const token = localStorage.getItem("admin_token");

  const [adsImage, setAdsImage] = useState(null);
  const [deletedImage, setDeletedImage] = useState(false);
  const [adsresponsiveImage, setAdsresponsiveImage] = useState(null);
  const [deletedresponsiveImage, setDeletedresponsiveImage] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;
  const [AdsformData, setAdsFormData] = useState({
    name: "",
    slug: "",
    background_color: "",
    title_one: "",
    title_two: "",
    description_one: "",
    description_two: "",
    link_one: "",
    link_two: "",
    is_active: 1,
  });

  const pageCount = Math.ceil(AdsList.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentAdsList = AdsList.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );
  useEffect(() => {
    fetchData();

    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif") ||
            url.endsWith(".webp") ||
            url.endsWith(".mp4")
        );
        setImageUrl(imageUrls);
      });
    }
  }, [token]);


  useEffect(() => {
    fetchData();

    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const responsiveUrls = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif") ||
            url.endsWith(".webp") ||
            url.endsWith(".mp4")
        );
        setresponsiveUrl(responsiveUrls);
      });
    }
  }, [token]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };

      const response = await fetch(
        "https://folcon.pureinfant.in/admin/all-ads",
        config
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();

      let AdsData = [];

      if (Array.isArray(responseData)) {
        AdsData = responseData;
      } else if (Array.isArray(responseData.data)) {
        AdsData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      SetAdsList(AdsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "name") {
      setAdsFormData({
        ...AdsformData,
        [id]: value,
        slug: generateSlug(value),
      });
    } else {
      setAdsFormData({
        ...AdsformData,
        [id]: value,
      });
    }
  };

  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, "-");
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setSelectedColorCode(color);
    setAdsFormData({
      ...AdsformData,
      color_code: color,
    });
  };

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
    setDeletedImage(true);
  };

  const handleDeletePreviews = () => {
    setresponsiveImage(null);
    setSelectedImages_two([]);
    setDeletedresponsiveImage(true);
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setAdsFormData({
      ...AdsformData,
      is_active: activeValue,
    });
  };

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const [mediaModalIsOpen, setMediaModalIsOpen] = useState(false);
  const [mediaModalIsOpens, setMediaModalIsOpens] = useState(false);

  const openMediaModal = () => {
    setMediaModalIsOpen(true);
  };

  const closeMediaModal = () => {
    setMediaModalIsOpen(false);
  };
  const openMediaModals = () => {
    setMediaModalIsOpens(true);
  };

  const closeMediaModals = () => {
    setMediaModalIsOpens(false);
  };

  const handleEdit = async (AdsId) => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };

      const response = await axios.get(
        `https://folcon.pureinfant.in/admin/get-ad/${AdsId}`,
        config
      );
      const responseData = response.data;
      setAdsFormData({
        name: responseData.data.name,
        slug: responseData.data.slug,
        background_color: responseData.data.background_color,
        title_one: responseData.data.title_one,
        title_two: responseData.data.title_two,
        description_one: responseData.data.description_one,
        description_two: responseData.data.description_two,
        link_one: responseData.data.link_one,
        link_two: responseData.data.link_two,
        image_path: responseData.data.image_path,
        responsive_image_path: responseData.data.responsive_image_path,
        is_active: responseData.data.is_active,
      });
      setSelectedadsId(AdsId);
      const previewImage = responseData.data.image_path
        ? AppUrl.concat(responseData.data.image_path)
        : null;

      const responsiveImage = responseData.data.responsive_image_path
        ? AppUrl.concat(responseData.data.responsive_image_path)
        : null;

      setPreviewImage(previewImage);
      setresponsiveImage(responsiveImage);
      setImag(responseData.data.image_path);
      setresponsiveImag(responseData.data.responsive_image_path);

      openEditModal();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      setSelectedImages(selectedImages.filter((i) => i !== index));
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setAdsImage(imageUrl[index]);
      closeMediaModal();
    }
  };

  const handleImageClicks = (index) => {
    if (selectedImages_two.includes(index)) {
      setSelectedImages_two(selectedImages_two.filter((i) => i !== index));
    } else {
      setSelectedImages_two([index]);
      setresponsiveImage(AppUrl + responsiveUrl[index]);
      setAdsresponsiveImage(responsiveUrl[index]);
      closeMediaModals();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("admin_token");
    const csrfToken = localStorage.getItem("csrfToken");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-CSRF-Token": csrfToken,
      },
    };

    try {
      if (!AdsformData.name || !AdsformData.slug || !selectedadsId) {
        toast.error("Please Fill The Form Data and select an ad to update");
        return;
      }

      const updatePayload = {
        ...AdsformData,
        ad_id: selectedadsId,

        image_path: adsImage,
        responsive_image_path: adsresponsiveImage,
      };

      setDeletedImage(false);
      setDeletedresponsiveImage(false);

      const newImageUrl = adsImage
        ? adsImage
        : AdsformData.image_path;

      const newImageUrls = adsresponsiveImage
        ? adsresponsiveImage
        : AdsformData.responsive_image_path;
      updatePayload.image_path = newImageUrl;
      updatePayload.responsive_image_path = newImageUrls;
      if (deletedImage === true) {
        updatePayload.image_path = null;
      }
      if (deletedresponsiveImage === true) {
        updatePayload.responsive_image_path = null;
      }

      const updateResponse = await axios.post(
        "https://folcon.pureinfant.in/admin/update-ad",
        updatePayload,
        config
      );
      console.log(updateResponse);

      if (updateResponse.data.status === "success") {
        toast.success("Ads updated successfully!");
        fetchData();
        setAdsFormData({
          name: "",
          slug: "",
          background_color: "",
          title_one: "",
          title_two: "",
          description_one: "",
          description_two: "",
          link_one: "",
          link_two: "",
          is_active: 0,
        });
      } else {
        toast.error("Ads update failed. Please try again.");
      }
    } catch (error) {
      console.error("Error updating ads:", error);
      toast.error("Ads update failed. Please try again.");
    }
    closeEditModal();
  };

  const header = (
    <></>
    // <div className="table-header">
    //   <div>
    //     <h3>Ads List</h3>
    //     <div>
    //       <span className="p-input-icon-left">
    //         <i className="pi pi-search" />
    //         <InputText
    //           type="search"
    //           value={globalFilter}
    //           onInput={(e) => setGlobalFilter(e.target.value)}
    //           placeholder="Search..."
    //         />
    //       </span>
    //     </div>
    //   </div>
    //   <div className="create-category-button">
    //     <button
    //       onClick={() => navigate("/admin/appearance-ads")}
    //       className="btn btn-primary"
    //     >
    //       Adds Create
    //     </button>
    //   </div>
    // </div>
  );

  const columns = [
    { field: "id", header: "S.No" },
    { field: "name", header: "Name" },
    { field: "slug", header: "Slug" },
    {
      field: "image_path",
      header: "Image",
      body: (rowData) => {
        if (rowData.image_path && rowData.image_path.endsWith("mp4")) {
          return (
            <video
              width={100}
              height={100}
              // controls
              autoPlay={true}
              src={AppUrl + rowData.image_path}
            />
          );
        } else if (rowData.image_path) {
          return (
            <img
              src={AppUrl + rowData.image_path}
              width={90}
              height={90}
              alt="ads"
            />
          );
        } else {
          return <img src={noImage} width={100} height={100} alt="No Image" />;
        }
      },
    },
    {
      field: "is_active",
      header: "Status",
      body: (rowData) => (
        <span
          className={
            rowData.is_active === 1 ? "active-status" : "inactive-status"
          }
        >
          {rowData.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "action",
      header: "Action",
      body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a
              data-bs-toggle="dropdown"
              className="btn btn-light rounded btn-sm font-sm"
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </a>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                onClick={() => handleEdit(rowData.id)}
              >
                Edit info
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];


  const filteredProducts = AdsList?.filter((product) =>
    product.name?.toLowerCase().includes(globalFilter.toLowerCase())
  );


  const startItem = pageNumber * itemsPerPage + 1;
  const endItem = Math.min((pageNumber + 1) * itemsPerPage, filteredProducts.length);

  return (
    <main id="main" className="main">



      <div className="pagetitle">
        <h1>Ads List</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashbord</a>
            </li>
            <li className="breadcrumb-item">Ads</li>
            <li className="breadcrumb-item active">Data</li>
          </ol>
        </nav>
      </div>
      <br />
      <div className="pagetitle">
        <div>
          <button onClick={() => navigate('/admin/appearance-ads')} className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;
            Create Ads
          </button>
        </div>
        <div className="search-bar">
          <span className="p-input-icon-left">
            <input
              type="search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="custom-input"
            />
          </span>
        </div>
      </div>
      <section className="section">
        <DataTable
          value={currentAdsList}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          header={header}
          emptyMessage="No Adds records found"
          className="react-data-table"
        >
          {columns.map((col, i) => (
            <Column key={i} field={col.field} header={col.header} body={col.body} />
          ))}
        </DataTable>

        <div className="pagetitle">
          <div className="results-info">
            Showing {startItem} - {endItem} of {filteredProducts?.length} results
          </div>
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
            nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>

      </section>


      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Adds Info"
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            //maxWidth: '80%',
            maxHeight: "100%",
            overflowY: "auto",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <div className="container" style={{ width: "900px" }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: "inline-block", marginRight: "10px" }}>
                Edit Ads info
              </h2>
            </div>
            <div className="col-auto"></div>
            <button
              onClick={closeEditModal}
              style={{
                fontSize: "30px",
                marginTop: "0",
                display: "flex",
                justifyContent: "flex-end",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ color: "#63E6BE" }}
              />
            </button>
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-6">
                <label htmlFor="name" className="col-sm-2 col-form-label">
                  Title
                </label>
                <div className="col-sm-8">
                  <input
                    id="name"
                    name="name"
                    value={AdsformData.name}
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="slug" className="col-sm-2 col-form-label">
                  Slug
                </label>
                <div className="col-sm-8">
                  <input
                    id="slug"
                    name="slug"
                    value={AdsformData.slug}
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={handleChange}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="description_one" className="col-form-label">
                  Description One
                </label>
                <div className="col-sm-8">
                  <input
                    id="description_one"
                    value={AdsformData.description_one}
                    name="description_one"
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="description_two" className=" col-form-label">
                  Description Two
                </label>
                <div className="col-sm-8">
                  <input
                    id="description_two"
                    value={AdsformData.description_two}
                    name="description_two"
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="title_one" className=" col-form-label">
                  Title One
                </label>
                <div className="col-sm-8">
                  <input
                    id="title_one"
                    name="title_one"
                    value={AdsformData.title_one}
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="title_two" className=" col-form-label">
                  Title Two
                </label>
                <div className="col-sm-8">
                  <input
                    id="title_two"
                    name="title_two"
                    type="text"
                    value={AdsformData.title_two}
                    placeholder="Type here"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="colorPicker" className="col-form-label">
                  Background Color
                </label>
                <div className="col-sm-8">
                  <div className="input-group">
                    <input
                      id="colorPicker"
                      type="text"
                      name="background_color"
                      value={selectedColor}
                      onChange={(e) => handleColorChange(e.target.value)}
                      disabled={isColorPickerDisabled}
                      className="form-control"
                      style={{
                        border: `2px solid ${selectedColor}`,
                        borderRadius: "5px",
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <span
                      className="input-group-text"
                      style={{
                        border: `2px solid ${selectedColor}`,
                        borderRadius: "5px",
                        backgroundColor: selectedColor,
                        color: "#000",
                        borderLeft: "none",
                        padding: "0px 5px",
                        width: "192px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="toggle" className="form-label">
                  Status
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggle"
                    name="is_active"
                    checked={AdsformData.is_active === 1}
                    onChange={handleToggleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="link_one" className=" col-form-label">
                  {" "}
                  Ads Link one
                </label>
                <div className="col-sm-8">
                  <input
                    id="link_one"
                    name="link_one"
                    type="text"
                    value={AdsformData.link_one}
                    placeholder="Type here"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="link_two" className=" col-form-label">
                  Ads Link two
                </label>
                <div className="col-sm-8">
                  <input
                    id="link_two"
                    name="link_two"
                    type="text"
                    value={AdsformData.link_two}
                    placeholder="Type here"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6 card-header">
                <h4>Preview</h4>
                <br />
                {previewImage ? (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    {previewImage.includes(".mp4") ? (
                      <video
                        src={previewImage}
                        alt="Preview"
                        style={{
                          maxWidth: "100%",
                          width: "200px",
                          height: "200px",
                        }}
                        autoPlay={true}
                      />
                    ) : (
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{
                          maxWidth: "100%",
                          width: "200px",
                          height: "200px",
                        }}
                      />
                    )}
                    <button
                      type="button"
                      onClick={handleDeletePreview}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                ) : (
                  <img src={UploadImage} alt="Alt" />
                )}
                <br></br>
                <button
                  type="button"
                  className="btn btn-primary mt-2 btn-sm btn-block"
                  onClick={openMediaModal}
                >
                  Select Image
                </button>
              </div>

              <div className="col-md-6 card-header">
                <h4>Responsive Image</h4>
                <br />
                {responsiveImage ? (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={responsiveImage}
                      alt="Preview"
                      style={{
                        maxWidth: "100%",
                        width: "200px",
                        height: "200px",
                      }}
                    />
                    <button
                      type="button"
                      onClick={handleDeletePreviews}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                ) : (
                  <img src={UploadImage} alt="Alt" />
                )}
                <br></br>
                <button
                  type="button"
                  className="btn btn-primary mt-2 btn-sm btn-block"
                  onClick={openMediaModals}
                >
                  Select Image
                </button>
              </div>




              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
        <SingleImageSelect
          isOpen={mediaModalIsOpen}
          handleClose={closeMediaModal}
          handleImageClick={handleImageClick}
          selectedImages={selectedImages}
          imageUrl={imageUrl}
          token={token}
          setImageUrl={setImageUrl}
        />

        <SingleImageSelect
          isOpen={mediaModalIsOpens}
          handleClose={closeMediaModals}
          handleImageClick={handleImageClicks}
          selectedImages={selectedImages_two}
          imageUrl={responsiveUrl}
          token={token}
          setImageUrl={setImageUrl}
        />
      </Modal>
    </main>
  );
};

export default AdsList;
