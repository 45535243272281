import React, { useEffect, useState } from 'react';
import AdminHeader from '../../../pages/views/Dashboard/AdminHeader';
import SideBar from '../../views/Dashboard/SideBar';
import ProductViewForm from '../../../components/Product/ProductViewForm';



const ProductView = () => {




  return (
    <>
      <AdminHeader />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Product View</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item">Product</li>
              <li className="breadcrumb-item active">Data</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <ProductViewForm />
        </section>
      </main>
    </>
  )
}

export default ProductView