import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  AdsDropDown,
  getSectionData,
  updateSection,
  ClassificationDropDown,
  CampaignDropDown
} from "../../../../service/apiService";
import Select from "react-select";
import { toast } from "react-toastify";
import ImageGallery from "../../../../components/Product/ImageGallery";
import VideoGallery from "../../../../components/Product/VideoGallery";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch"

const SectionEditModel = ({ isOpen, onRequestClose, sectionId, sectionGroupId, limit, pageid }) => {
  const [editFormData, setEditFormData] = useState({});
  const [editAdsDropdown, setEditAdsDropdown] = useState([]);

  const [editSectionDropdownData, setEditSectionDropdownData] = useState([]);
  //single dropdown
  const [editSingleDropDown, setEditSingleDropDown] = useState([]);

  ///Image and vedio 
  const AppUrl = "https://folcon.pureinfant.in/api/files/";
  const [editSelectedImageMedia, setEditSelectedImageMedia] = useState([]);
  const [editSelectedVideoMedia, setEditSelectedVideoMedia] = useState([]);
  const [EditisImageGalleryOpen, setEditIsImageGalleryOpen] = useState(false);
  const [editCampaignData, seteditCampaignData] = useState([]);
  const [EditsVideoGalleryOpen, seteditIsVideoGalleryOpen] = useState(false);
  const [EditerrorMessage, setEditErrorMessage] = useState("");
  const [mapId, setMapId] = useState([]);
  const [section14ImgId, setSection14ImgId] = useState(null);
  const [sec14FormData, setSec14FormData] = useState([
    { key_name: "TITLE_1", key_value: "" },
    { key_name: "IMG_1", key_value: "" },
    { key_name: "DESC_1", key_value: "" },
    { key_name: "LINK_1", key_value: "" },
    { key_name: "TITLE_2", key_value: "" },
    { key_name: "IMG_2", key_value: "" },
    { key_name: "DESC_2", key_value: "" },
    { key_name: "LINK_2", key_value: "" },
    { key_name: "TITLE_3", key_value: "" },
    { key_name: "IMG_3", key_value: "" },
    { key_name: "DESC_3", key_value: "" },
  ]);

  const [Editsection23ImgId, setEditSection23ImgId] = useState(null);
  const [editsec23, seteditsec23] = useState([
    { "key_name": "LINK", "key_value": "" },
    { "key_name": "BG_IMG", "key_value": "" },
    { "key_name": "IMG", "key_value": "" },
  ])

  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);


  const editHandleImageMediaSelect = (mediaUrls) => {
    console.log(mediaUrls)
    const newSelectedMedia = [...editSelectedImageMedia, ...mediaUrls].slice(0, 12);
    setEditSelectedImageMedia(newSelectedMedia);
    setEditIsImageGalleryOpen(false);
  };
  const handleVideoMediaSelect = (mediaUrls) => {
    setEditSelectedVideoMedia(
      limit === 1 ? [mediaUrls[0]] : [...editSelectedVideoMedia, ...mediaUrls]
    );
    seteditIsVideoGalleryOpen(false);
  };
  const handleDeleteMedia = (type, index) => {
    if (type === "image") {
      setEditSelectedImageMedia(editSelectedImageMedia.filter((_, i) => i !== index));
    } else if (type === "video") {
      setEditSelectedVideoMedia(editSelectedVideoMedia.filter((_, i) => i !== index));
    }
  };



  //  { getting section number }
  const extractSectionNumber = (input) => {
    const match = input.match(/section(\d+)_/);
    if (match) {
      return parseInt(match[1], 10);
    }
    return null;
  }

  //section id 
  const SectionId_Number = extractSectionNumber(sectionId);
  // console.log(SectionId_Number)

  const token = localStorage.getItem("admin_token");


  ///handlesubmit for 1,3,10
  const handleChange_1310 = async (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // HandleChange
  const handleChange = (selectedOption) => {

    console.log(selectedOption);


    const selectedValues = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    setEditFormData((prevData) => ({
      ...prevData,
      ADS: selectedValues,
    }));
  };



  const handleChange01 = (selectedOption, index) => {
    const selectedValues = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    const updatedFormData = [...sec14FormData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      key_value: selectedValues.join(",")
    };
    console.log(updatedFormData[index]);
    setSec14FormData(updatedFormData);
  };




  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  //handle change for section 5
  const handle_Chnage_05_Text_Field = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  // Fetching  dropdown data when the edit model is open 
  useEffect(() => {
    if (isOpen) {
      EditfetchAdsDropdown();
      editFetchAdsDropdownSingle();
      editFetchClassificationDropdown();
      EdicampaignDropdown();
    }
  }, [isOpen]);


  // {function to get the sectionMapId }
  const getSectionMapId = (response) => {
    const sectionsData = response.sections_data;
    if (sectionsData && sectionsData.length > 0) {
      return sectionsData;
    }
    return null;
  }
  const clearData = () => {
    setEditSelectedImageMedia([])
    setEditSelectedVideoMedia([])
    setEditFormData({})
  }
  // Api Called to fill the data In the sections
  useEffect(() => {
    if (SectionId_Number) {
      const fetchSectionDetails = async () => {
        const response = await getSectionData(token, sectionGroupId, pageid);
        if (response.data) {
          const data = response.data;
          const transform = getSectionMapId(data)
          setMapId(transform)
          const ads = data.sections_data?.length ? data.sections_data : [];
          // All data
          let obj = {}
          let obj_section_14 = []
          if (SectionId_Number === 1 || SectionId_Number === 23) {
            obj_section_14 = ads?.filter((item) => {
              let obj = {}
              obj["key_name"] = item?.key_name;
              obj["key_value"] = item?.key_value;
              return obj;
            })
          } else {
            ads?.map((item) => {
              if (item?.key_name === "File" && item?.key_value) {
                let arr = item?.key_value?.split(",")
                if (arr[0]?.includes('jpg')) {
                  setEditSelectedImageMedia(arr)
                }
                if (arr[0]?.includes('mp4')) {
                  setEditSelectedVideoMedia(arr)
                }
              }
              obj[item?.key_name === "CLASSIFI" ? "CLASSIFICATION" : item?.key_name] = item?.key_value
            })
          }
          if (SectionId_Number === 1) {
            setSec14FormData([...obj_section_14])
          }
          if (SectionId_Number === 23) {
            seteditsec23([...obj_section_14])
          }
          setEditFormData({
            ...editFormData,
            ...obj
          });
        }
      };
      fetchSectionDetails();
    }
  }, [SectionId_Number, isOpen]);



  const returnSec_14_payload = (key) => {
    let obj = sec14FormData?.find((item) => item?.key_name === key);
    return obj?.key_value
  }
  const returnSec_23_payload = (key) => {
    let obj = editsec23?.find((item) => item?.key_name === key);
    return obj?.key_value
  }

  const section02Payload = (key) => {
    console.log("Key to match:", key);

    if (editFormData.hasOwnProperty(key)) {
      const value = editFormData[key];
      console.log("Matched value:", value);
      return value;
    } else {
      console.log("Key not found.");
      return null;
    }
  };





  // Dynamic PlayLoad for sectins 
  const createPayload = (id, key) => {
    switch (id) {
      case 2:
        return section02Payload(key);
      case 3:
        return key === 'ADS' ? editFormData[key].join(",") : key === "File" ? editSelectedImageMedia?.join(",") : null
      case 4:
        return key === "TITLE" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : editFormData[key];
      case 5:
        return key === "TITLE" ? editFormData[key] : key === "BLOG" ? editFormData[key].toString() : null;
      case 6:
        return section02Payload(key);
      case 7:
        return key === "TITLE" ? editFormData[key] : key === "BRAND" ? editFormData[key].toString() : null;
      case 8:
      case 9:
        return key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : key === "TITLE" ? editFormData[key] : null
      case 10:
        return key === "CAMP" ? editFormData[key].toString() : key === "TITLE" ? editFormData[key] : null
      // case 15:
      //   return key === "PRE_ORDER" ? editFormData[key].toString() : null
      // case 9:
      //   return key === "TITLE" ? editFormData[key] : typeof (editFormData[key]) === 'string' ? editFormData[key] : editFormData[key]?.join(",");
      // case 19:
      //   return key === "TITLE" ? editFormData[key] : typeof (editFormData[key]) === 'string' ? editFormData[key] : editFormData[key]?.join(",");
      // case 5:
      //   return key === 'ADS' ? editFormData[key].join(",") : editFormData[key]
      // case 7:
      // case 6:
      //   return section06Payload(key);
      case 11:
        return key === 'File' ? editSelectedImageMedia?.join(",") : editFormData[key];
      case 12:
      case 13:
        return key === "TITLE" ? editFormData[key] : key === "TITLE_1" ? editFormData[key] : key === "DESC_1" ? editFormData[key] : key === "DESC_2" ? editFormData[key] : editFormData[key]
      case 18:
        return key === 'ADS' ? editFormData[key].join(",") : key === "TITLE" ? editFormData[key] : key === "File" ? editSelectedImageMedia?.join(",") : editSelectedVideoMedia?.join(",");
      case 1:
        return returnSec_14_payload(key);
      case 20:
        return `${editFormData[key]}`;
      case 21:
        return `${editFormData[key]}`
      case 22:
        return section02Payload(key)
      case 23:
        return returnSec_23_payload(key);
      case 35:
        return key === "TITLE" ? editFormData[key] : editFormData[key]
      default:
        return '';
    }
  };
  const extractSectionMapId = (key) => {
    let keyName = key;
    if (key === "File_Video") {
      keyName = "File"
    }
    let obj = mapId?.find(item => {
      console.log(item?.key_name, keyName)
      if (item?.key_name === keyName) return item;
    })
    return obj ? obj?.section_map_id : -1
  }

  // Api  UpdateSection
  const handleEditSectionSubmit = async (e, key) => {
    e.preventDefault();
    try {
      let payload = {
        section_group: sectionGroupId,
        section_map_id: extractSectionMapId(key),
        key_value: createPayload(SectionId_Number, key),
        // is_active: onSuccess
      };
      // console.log("payload", payload);
      const res = await updateSection(token, payload);
      if (res) {
        console.log(res);
        toast.success("Section updated successfully!");
        clearData();
        onRequestClose();
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Failed to update section.");
    }
  };


  // API fetched  DATA FOR THE DROPDOWN
  const EditfetchAdsDropdown = async () => {
    const response = await AdsDropDown(token);
    setEditAdsDropdown(
      response.data.map((option) => ({
        value: option.id,
        label: option.name.toUpperCase(),
      }))
    );
  };



  const EdicampaignDropdown = async () => {
    const response = await CampaignDropDown(token);
    seteditCampaignData(
      response.data?.map((option) => ({
        value: option.campaign_id,
        label: option.campaign_name.toUpperCase(),
      }))
    );
  };


  //API fetched for single dropdown data 
  const editFetchAdsDropdownSingle = async () => {
    const response = await AdsDropDown(token);
    setEditSingleDropDown(response.data);
  };

  // Api fetched for classifications
  const editFetchClassificationDropdown = async () => {
    const classifications = await ClassificationDropDown(token);
    console.log(classifications?.data)
    setEditSectionDropdownData(
      classifications.data.map((option) => ({
        value: option.classification_id,
        label: option.classification_name.toUpperCase(),
      }))
    );
  };

  const sec14_ImageGalleryOpen = (rowIndex) => {
    setSection14ImgId(rowIndex);
    setIsImageGalleryOpen(true);
    setEditIsImageGalleryOpen(true);
  };

  const sec14HandleOnChange = (event, index) => {
    console.log(sec14FormData[index]);
    const updatedFormData = [...sec14FormData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      key_value: event.target?.value
    };

    console.log(updatedFormData);
    setSec14FormData(updatedFormData);
  };


  const sec14_ImageGalleryHandle = (mediaUrl) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[section14ImgId] = {
      ...updatedFormData[section14ImgId],
      key_value: mediaUrl
    };
    setSec14FormData(updatedFormData);
  };
  const sec14_handleDeleteMedia = (index) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[index].key_value = "";
    setSec14FormData(updatedFormData);
  };



  // {  sections23}

  const sec23_Edit_ImageGalleryOpen = (rowIndex, keyName) => {
    setEditSection23ImgId({ rowIndex, keyName });
    // setIsImageGalleryOpen(true);
    setEditIsImageGalleryOpen(true);
  };

  const sec23_ImageGalleryHandle = (mediaUrl) => {
    const { keyName } = Editsection23ImgId;
    const updatedFormData = [...editsec23];
    const index = updatedFormData.findIndex((item) => item.key_name === keyName);
    updatedFormData[index] = {
      ...updatedFormData[index],
      key_value: mediaUrl
    };
    seteditsec23(updatedFormData);
  };

  const sec23HandleOnchange = (event) => {
    const { name, value } = event.target;
    seteditsec23((prevFormData) =>
      prevFormData.map((item) =>
        item.key_name === name ? { ...item, key_value: value } : item
      )
    );
  };

  const sec23_handleDeleteMedia = (index) => {
    const updatedFormData = [...editsec23];
    updatedFormData[index].key_value = "";
    seteditsec23(updatedFormData);
  };



  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Edit Section"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "15px",
            padding: "20px",
            // maxWidth: "1500px",
            // minWidth: "114500px",
            width: "1300px",
            maxHeight: "700px",
            minHeight: "800px",
            overflowY: "auto",
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <div className="modal-header">
          <h2>Edit Section</h2>
          <button
            onClick={() => {
              onRequestClose();
              clearData();
            }}
          >
            Close
          </button>
        </div>
        {/* Section Input fields */}
        <form>
          {EditerrorMessage && <div className="error-message">{EditerrorMessage}</div>}
          {/* section 1 */}
          {[1].includes(parseInt(SectionId_Number)) && (
            <div style={{ paddingBottom: '20px' }}>
              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput1`}>Title 1</label>
                    <input
                      id={`titleInput1`}
                      type="text"
                      name={sec14FormData[0]?.key_name}
                      value={sec14FormData[0]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 0)}
                      className="form-control"
                      placeholder={`Enter title 1`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[0].key_name)}>
                      Edit
                    </button>
                  </div>
                  <div>
                    <label htmlFor="titleInput">Description 1</label>
                    <textarea
                      id="titleInput2"
                      // name="DESC"
                      // value={editFormData?.DESC}
                      name={sec14FormData[2].key_name}
                      value={sec14FormData[2].key_value}
                      onChange={handleUpdateChange}
                      className="form-control"
                      placeholder="Enter another title here"
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[2].key_name)}>
                      Edit Description
                    </button>
                  </div>

                  <div className="input-container form-group">
                    <label htmlFor={`titleInput1`}>Link 1</label>
                    <input
                      id={`titleInput1`}
                      type="text"
                      name={sec14FormData[3]?.key_name}
                      value={sec14FormData[3]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 3)}
                      className="form-control"
                      placeholder={`Enter title 1`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[3].key_name)}>
                      Edit
                    </button>
                  </div>
                </div>
                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(1)}
                  >
                    Image Gallery 1
                  </button>
                </div>
                {sec14FormData[1]?.key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[1].key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(1)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[1].key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ************************ 1*/}


              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput2`}>Title 2</label>
                    <input
                      id={`titleInput2`}
                      type="text"
                      name={sec14FormData[4]?.key_name}
                      value={sec14FormData[4]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 4)}
                      className="form-control"
                      placeholder={`Enter title 2`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[4].key_name)}>
                      Edit
                    </button>

                    <div>
                      <label htmlFor="titleInput">Description 2</label>
                      <textarea
                        id="titleInput2"
                        // name="DESC"
                        // value={editFormData?.DESC}
                        name={sec14FormData[6].key_name}
                        value={sec14FormData[6].key_value}
                        onChange={handleUpdateChange}
                        className="form-control"
                        placeholder="Enter another title here"
                      />
                      <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[6].key_name)}>
                        Edit Description
                      </button>
                    </div>

                    <div className="input-container form-group">
                      <label htmlFor={`titleInput1`}>Link 2</label>
                      <input
                        id={`titleInput1`}
                        type="text"
                        name={sec14FormData[7]?.key_name}
                        value={sec14FormData[7]?.key_value}
                        onChange={(event) => sec14HandleOnChange(event, 7)}
                        className="form-control"
                        placeholder={`Enter link`}
                      />
                      <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[7].key_name)}>
                        Edit
                      </button>
                    </div>
                  </div>




                  <div className="image-gallery-container">
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() => sec14_ImageGalleryOpen(5)}
                    >
                      Image Gallery 2
                    </button>
                  </div>
                  {sec14FormData[5]?.key_value && (
                    <div className="media-preview">
                      <div className="media-items">
                        <div className="media-item">
                          <img
                            src={AppUrl + sec14FormData[5]?.key_value}
                            alt="Selected"
                            style={{ width: '150px', height: '200px' }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginLeft: '30px' }}
                            onClick={() => sec14_handleDeleteMedia(5)}
                          >
                            Delete
                          </button>
                          <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[5].key_name)}>
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* ************************2 */}
              </div>


              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput3`}>Title 3</label>
                    <input
                      id={`titleInput3`}
                      type="text"
                      name={sec14FormData[8]?.key_name}
                      value={sec14FormData[8]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 8)}
                      className="form-control"
                      placeholder={`Enter title 3`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[8].key_name)}>
                      Edit
                    </button>
                  </div>



                  <div>
                    <label htmlFor="titleInput">Description 3</label>
                    <textarea
                      id="titleInput2"
                      name={sec14FormData[10].key_name}
                      value={sec14FormData[10].key_value}
                      onChange={handleUpdateChange}
                      className="form-control"
                      placeholder="Enter another title here"
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[10].key_name)}>
                      Edit Description
                    </button>
                  </div>

                  <div className="input-container form-group">
                    <label htmlFor={`titleInput1`}>Link 3</label>
                    <input
                      id={`titleInput1`}
                      type="text"
                      name={sec14FormData[11]?.key_name}
                      value={sec14FormData[11]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 11)}
                      className="form-control"
                      placeholder={`Enter link`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[11].key_name)}>
                      Edit
                    </button>
                  </div>




                </div>
                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(9)}
                  >
                    Image Gallery 3
                  </button>
                </div>
                {sec14FormData[9]?.key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[9].key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(9)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[9].key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>


              <div className="dropdown-container">
                {EditerrorMessage.length > 0 ? (
                  <div className="Error-Msg">{EditerrorMessage}</div>
                ) : (
                  <p className="DeFault-Msg"> Select an Ad</p>
                )}
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    sec14FormData[12]?.key_value
                      ? editAdsDropdown.filter((option) =>
                        sec14FormData[12].key_value?.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    handleChange01(selectedOption, 12)
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[12].key_name)}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[2].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
                <div>
                  <label htmlFor="titleInput">Description 1</label>
                  <textarea
                    id="titleInput2"
                    name="DESC"
                    value={editFormData?.DESC}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter another title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "DESC")}>
                    Edit Description
                  </button>
                </div>
              </div>


              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 2: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
                <div>
                  <label htmlFor="titleInput">Description </label>
                  <textarea
                    id="titleInput2"
                    name="DESC_1"
                    value={editFormData?.DESC_1}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter another title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "DESC_1")}>
                    Edit Description
                  </button>
                </div>
              </div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 3: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_2"
                  value={editFormData.TITLE_2}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                  Edit
                </button>
                <div>
                  <label htmlFor="titleInput">Description </label>
                  <textarea
                    id="titleInput2"
                    name="DESC_2"
                    value={editFormData?.DESC_2}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter another title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "DESC_2")}>
                    Edit Description
                  </button>
                </div>
              </div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 4: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_3"
                  value={editFormData.TITLE_3}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_3")}>
                  Edit
                </button>
                <div>
                  <label htmlFor="titleInput">Description </label>
                  <textarea
                    id="titleInput2"
                    name="DESC_3"
                    value={editFormData?.DESC_3}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter another title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "DESC_3")}>
                    Edit Description
                  </button>
                </div>
              </div>
            </div>
          )}
          {[3].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">News Letter Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="NL_TITLE"
                  value={editFormData.NL_TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "NL_TITLE")}>
                  Edit
                </button>
              </div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">News Letter Description: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="NL_DESC"
                  value={editFormData.NL_DESC}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "NL_DESC")}>
                  Edit
                </button>
              </div>

              <label htmlFor="editAdsDropdown">Ads</label>
              <Select
                id="editAdsDropdown"
                name="ADS"
                value={
                  editFormData.ADS
                    ? editAdsDropdown.filter((option) =>
                      editFormData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length > 2) {
                    setEditErrorMessage("You can select a maximum of 2 ads.");
                  }

                  else {
                    handleChange(selectedOption);
                    setEditErrorMessage("");
                  }
                }}
                options={editAdsDropdown}
                isMulti
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                Edit
              </button>

              <br />
              <div className="dropdown-container">
                {limit === 0 ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() => setEditIsImageGalleryOpen(true)}
                    >
                      Image Gallery -IMG
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() =>
                        setEditIsImageGalleryOpen(editSelectedImageMedia.length === 0)
                      }
                      disabled={editSelectedImageMedia.length > 0}
                    >
                      Image Gallery -IMG
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {[4].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>


              <div className="input-container form-group">


                <label htmlFor="editAdsDropdown">Ads</label>
                <select
                  id="adsDropdown2"
                  name="ADS"
                  value={editFormData.ADS || ""}
                  onChange={handleChange_1310}
                  className="dropdown"
                >
                  <option value="">Select an Ads</option>
                  {editSingleDropDown.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name.toUpperCase()}
                    </option>
                  ))}
                </select>
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit
                </button>
              </div>

              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={editSectionDropdownData.filter(
                  (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                )}
                onChange={(selectedOption) =>
                  setEditFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={editSectionDropdownData}
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                Edit
              </button>

            </div>
          )}
          {[5].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Active Blogs:</label>
                <ToggleSwitch
                  checked={Number(editFormData.BLOG == 1)}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      BLOG: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "BLOG")}>
                  Edit Toggle
                </button>
              </div>
            </div>
          )}
          {[6].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 2: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_2"
                  value={editFormData.TITLE_2}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                  Edit
                </button>
              </div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">Discription </label>
                <input
                  id="titleInput"
                  type="text"
                  name="DESC"
                  value={editFormData.DESC}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "DESC")}>
                  Edit
                </button>
              </div>

              <label htmlFor="titleInput">Button Name</label>
              <input
                id="titleInput1"
                type="text"
                name="BTN"
                value={editFormData?.BTN}
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title here"
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "BTN")}>
                Edit button
              </button>

              <div>
                <label htmlFor="titleInput">Link</label>
                <input
                  id="titleInput1"
                  type="text"
                  name="LINK"
                  value={editFormData?.LINK}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title here"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "LINK")}>
                  Edit Link
                </button>
              </div>

            </div>

          )}
          {[7].includes(SectionId_Number) && (
            <div>
              <div>
                <div className="input-container form-group">
                  <label htmlFor="titleInput">Title: </label>
                  <input
                    id="titleInput"
                    type="text"
                    name="TITLE"
                    value={editFormData.TITLE}
                    onChange={handle_Chnage_05_Text_Field}
                    className="form-control"
                    placeholder="Enter title"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                    Edit
                  </button>
                </div>

                <div className="dropdown-container">
                  <label htmlFor="adsDropdown2">Active Blogs:</label>
                  <ToggleSwitch
                    checked={Number(editFormData.BRAND == 1)}
                    onChange={(e) =>
                      setEditFormData((prevData) => ({
                        ...prevData,
                        BRAND: e.target.checked ? 1 : 0,
                      }))
                    }
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "BRAND")}>
                    Edit Toggle
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* *********************** */}
          {[11].includes(parseInt(SectionId_Number)) && (
            <div className="conditional-container">
              <div className="dropdown-container">
                <div className="input-container form-group">
                  <label htmlFor="titleInput1">Title 1: </label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="TITLE"
                    value={editFormData.TITLE}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title 1"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                    Edit
                  </button>
                </div>
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length > 3) {
                      setEditErrorMessage("You can select a maximum of 3 ads.");
                    }

                    else {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit
                </button>
              </div>

            </div>
          )}
          {/* section 8 */}
          {[8].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={editSectionDropdownData.filter(
                  (option) => option.value === parseInt(editFormData.CLASSIFICATION)
                )}
                onChange={(selectedOption) =>
                  setEditFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={editSectionDropdownData}
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                Edit
              </button>
            </div>
          )}
          {[9].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={editSectionDropdownData.filter(
                  (option) => option.value === parseInt(editFormData.CLASSIFICATION)
                )}
                onChange={(selectedOption) =>
                  setEditFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={editSectionDropdownData}
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                Edit
              </button>
            </div>
          )}
          {/* section 10 */}
          {[10].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="Campaign">CAMPAIGN</label>
                <Select
                  id="CampaignDropdown"
                  name="CAMP"
                  value={editCampaignData.filter(
                    (option) => option.value === parseInt(editFormData.CAMP)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CAMP: selectedOption.value,
                    }))
                  }
                  options={editCampaignData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CAMP")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[12].includes(SectionId_Number) && (
            <div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>


              <div className="input-container form-group">
                <label htmlFor="titleInput">Derscription 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="DESC_1"
                  value={editFormData.DESC_1}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "DESC_1")}>
                  Edit
                </button>
              </div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">Derscription 2: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="DESC_2"
                  value={editFormData.DESC_2}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "DESC_2")}>
                  Edit
                </button>
              </div>

              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <select
                  id="adsDropdown2"
                  name="ADS"
                  value={editFormData.ADS || ""}
                  onChange={handleChange_1310}
                  className="dropdown"
                >
                  <option value="">Select an Ads</option>
                  {editSingleDropDown.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name.toUpperCase()}
                    </option>
                  ))}
                </select>
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
          {[13].includes(SectionId_Number) && (
            <div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>


              <div className="input-container form-group">
                <label htmlFor="titleInput">Derscription 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="DESC_1"
                  value={editFormData.DESC_1}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "DESC_1")}>
                  Edit
                </button>
              </div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">Derscription 2: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="DESC_2"
                  value={editFormData.DESC_2}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "DESC_2")}>
                  Edit
                </button>
              </div>

              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <select
                  id="adsDropdown2"
                  name="ADS"
                  value={editFormData.ADS || ""}
                  onChange={handleChange_1310}
                  className="dropdown"
                >
                  <option value="">Select an Ads</option>
                  {editSingleDropDown.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name.toUpperCase()}
                    </option>
                  ))}
                </select>
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
          {[35].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <select
                  id="adsDropdown2"
                  name="ADS"
                  value={editFormData.ADS || ""}
                  onChange={handleChange_1310}
                  className="dropdown"
                >
                  <option value="">Select an Ads</option>
                  {editSingleDropDown.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name.toUpperCase()}
                    </option>
                  ))}
                </select>
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="media-preview-container">
            {editSelectedImageMedia.length > 0 && (
              <div className="media-preview">
                <h3>Selected Images</h3>
                <div className="media-items">
                  {editSelectedImageMedia.map((mediaUrl, index) => (
                    <div key={index} className="media-item">
                      <img
                        src={AppUrl + mediaUrl}
                        alt="Selected"
                        width={"250px"}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteMedia("image", index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button onClick={(e) => handleEditSectionSubmit(e, "File")}>
                    Edit
                  </button>
                </div>
              </div>
            )}
            {editSelectedVideoMedia.length > 0 && (
              <div className="media-preview">
                <h3>Selected Videos</h3>
                <div className="media-items">
                  {editSelectedVideoMedia.map((mediaUrl, index) => (
                    <div key={index} className="media-item">
                      <video
                        src={AppUrl + mediaUrl}
                        controls
                        width={"250px"}
                        height={"200px"}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteMedia("video", index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button onClick={(e) => handleEditSectionSubmit(e, "File_Video")}>
                    Edit
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
        <ImageGallery
          isOpen={EditisImageGalleryOpen}
          onRequestClose={() => setEditIsImageGalleryOpen(false)}
          onMediaSelect={editHandleImageMediaSelect}
          selectedMedia={editSelectedImageMedia}
          setSelectedMedia={setEditSelectedImageMedia}
          limit={3}
          sectionId={sectionId}
          sec14_ImageGalleryHandle={sec14_ImageGalleryHandle}
          sec23_ImageGalleryHandle={sec23_ImageGalleryHandle}
          SectionId_Number={SectionId_Number}
        />
        <VideoGallery
          isOpen={EditsVideoGalleryOpen}
          onRequestClose={() => seteditIsVideoGalleryOpen(false)}
          onMediaSelect={handleVideoMediaSelect}
          selectedMedia={editSelectedVideoMedia}
          setSelectedMedia={setEditSelectedVideoMedia}
          limit={limit}
        />
      </Modal>
    </>
  );
};
export default SectionEditModel;






