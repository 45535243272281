import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetSettingList, MediaList, GetsubpageList } from "../../../service/apiService";
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import UploadImage from "../../../assets/images/defimg.jpeg";
import { faArrowLeft, faArrowRight, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {
  faStreetView,
  faCircleXmark,
  faEdit,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "react-virtualized";

const SettingList = () => {
  const [Setting, setSetting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [editingImage, setEditingImage] = useState(null);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [thumnailImage, setthumnailImage] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [specification, setSpecification] = useState('');
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [subPages, setSubPages] = useState([]);
  const token = localStorage.getItem("admin_token");
  const AppUrl = "https://folcon.pureinfant.in/api/files/";
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 8;

  const pageCount = Math.ceil(Setting.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentSetting = Setting.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );
  const handleImageUpload = (id, file) => {
    setEditingId(id);
    setEditingImage(file);
    setEditingValue(""); // Reset text editing state
  };

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      setSelectedImages(selectedImages.filter((i) => i !== index));
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setEditingImage(AppUrl + imageUrl[index]); // Set the editing image to the selected one
      closeModal();
    }
  };

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };






  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });

    }
  }, [token]);


  useEffect(() => {
    fetchData();
    fetchSubpageData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const response = await GetSettingList(token);
      setSetting(response.data);
    } catch (error) {
      console.log(error);
    }
  };



  const fetchSubpageData = async () => {
    try {
      const response = await GetsubpageList(token);

      setSubPages(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  // const handleEdit = (id, value) => {
  //   setEditingId(id);
  //   const filename = value.split('/').pop();
  //   //console.log(filename);
  //   setEditingValue(filename);
  //   setEditingImage(null);
  // };

  const handleEdit = (id, value, keyName) => {
    setEditingId(id);
    if (keyName === "AUTH_PAGE_LINE_1" || keyName === "AUTH_PAGE_LINE_2") {
      setSelectedPageId(value);
    }
    setEditingValue(value);
    setEditingImage(null); // Reset image editing state
  };

  const handleSave = async (id) => {
    try {
      const settingToUpdate = Setting.find((setting) => setting.id === id);
      if (!settingToUpdate) {
        throw new Error("Setting not found");
      }

      let updatedSetting;
      if (editingImage) {
        updatedSetting = {
          id: settingToUpdate.id,
          key_name: settingToUpdate.key_name,
          key_value: editingImage.split('/').pop(),
          is_active: settingToUpdate.is_active,
        };
      } else {
        updatedSetting = {
          id: settingToUpdate.id,
          key_name: settingToUpdate.key_name,
          key_value: editingValue,
          is_active: settingToUpdate.is_active,
        };
      }

      if (settingToUpdate.key_name === "AUTH_PAGE_LINE_1" || settingToUpdate.key_name === "AUTH_PAGE_LINE_2") {
        updatedSetting.key_value = selectedPageId;
      }
      const csrfToken = localStorage.getItem("csrfToken");

      const response = await axios.post(
        "https://folcon.pureinfant.in/admin/update-setting",
        updatedSetting,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken,
          },
        }
      );

      if (response.status === 200) {

        setSetting((prevSettings) =>
          prevSettings.map((setting) =>
            setting.id === id
              ? {
                ...setting,
                key_value: editingImage ? editingImage : editingValue,
                key_value: updatedSetting.key_value,
              }
              : setting
          )
        );
        toast.success(response.data.message || "Setting updated successfully!");
        setEditingId(null); // Reset editing state
      } else {
        toast.error("Failed to update setting.");
      }
    } catch (error) {
      console.log("API Error:", error);
      toast.error("Failed to update setting.");
    }
  };


  const header = (
    <div className="table-header">
      <div>
        <h3>Setting</h3>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
    </div>
  );

  const columns = [
    { field: "id", header: "ID", sortable: true },
    { field: "key_name", header: "Name", sortable: true },
    {
      field: "key_value",
      header: "Value",
      sortable: true,
      body: (rowData) => {
        if (editingId === rowData.id) {
          if (
            rowData.key_name === "LOGIN_PAGE_IMG" ||
            rowData.key_name === "SITE_LOGO" ||
            rowData.key_name === "FAV_ICON" ||
            rowData.key_name === "CONTACT_US_BG_IMG"

          ) {
            return (
              <>
                <button
                  className="btn btn-primary mt-2 btn-sm btn-block"
                  onClick={openModal}
                  style={{ marginRight: 50 }}
                >
                  Select Image
                </button>
                {rowData.key_value && (
                  <>
                    {previewImage ? (
                      <div style={{ position: "relative", display: "inline-block" }}>
                        <img
                          src={previewImage}
                          alt="Preview"
                          style={{

                            width: "50px",

                          }}
                        />
                        {/* <button
                                                  type="button"
                                                  onClick={handleDeletePreview}
                                                  style={{
                                                      position: "absolute",
                                                      top: "5px",
                                                      right: "5px",
                                                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                                                      border: "none",
                                                      cursor: "pointer",
                                                  }}
                                              >
                                                  <FontAwesomeIcon icon={faTrashAlt} />
                                              </button> */}
                      </div>
                    ) : (
                      <img src={AppUrl + rowData.key_value} alt="Alt" width={50} />
                    )}
                  </>
                )}
              </>
            );
          }

          else if (rowData.key_name === "FOOTER_CONTENT_ONE" ||
            rowData.key_name === "FOOTER_CONTENT_TWO") {

            return (
              <CKEditor
                editor={ClassicEditor}
                data={editingValue}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditingValue(data);
                }}
                config={{
                  toolbar: [
                    'heading', '|',
                    'bold', 'italic', 'blockQuote', '|',
                    'numberedList', 'bulletedList', '|',
                    'undo', 'redo'
                  ],
                }}
              />
            );

          }
          else if (rowData.key_name === "AUTH_PAGE_LINE_1" ||
            rowData.key_name === "AUTH_PAGE_LINE_2") {

            return (
              <select
                value={selectedPageId}
                onChange={(e) => setSelectedPageId(e.target.value)}
              >
                <option value="">Select Page</option>
                {subPages && subPages.map((page) => (
                  <option key={page.page_id} value={page.page_id} selected={rowData.key_value == page.page_id}>
                    {page.page_name}
                  </option>
                ))}
              </select>
            );



          }

          else {
            return (
              <InputText
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
              />
            );
          }


        } else {
          if (
            rowData.key_name === "LOGIN_PAGE_IMG" ||
            rowData.key_name === "SITE_LOGO" ||
            rowData.key_name === "FAV_ICON" ||
            rowData.key_name === "CONTACT_US_BG_IMG"
          ) {
            return rowData.key_value ? (
              <>
                {previewImage ? (
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <img
                      src={rowData.key_value.startsWith(AppUrl) ? rowData.key_value : `${AppUrl}${rowData.key_value}`}
                      alt="Preview"
                      style={{

                        width: "50px",

                      }}
                    />
                    {/* <button
                                        type="button"
                                        onClick={handleDeletePreview}
                                        style={{
                                            position: "absolute",
                                            top: "5px",
                                            right: "5px",
                                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                                            border: "none",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </button> */}
                  </div>
                ) : (
                  <img src={AppUrl + rowData.key_value} alt="Alt" width={50} />
                )}
              </>
            ) : null;
          } else {
            if (rowData.key_name === "AUTH_PAGE_LINE_1" || rowData.key_name === "AUTH_PAGE_LINE_2") {
              const selectedPage = subPages.find((page) => page.page_id === parseInt(rowData.key_value));
              return selectedPage ? selectedPage.page_name : "N/A";
            }
            return rowData.key_value;

          }
        }

      },
    },
    {
      field: "is_active",
      sortable: true,
      header: "Status",
      body: (rowData) => (
        <span
          className={
            rowData.is_active === 1 ? "active-status" : "inactive-status"
          }
        >
          {rowData.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "action",
      header: "Action",
      body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a
              href="#"
              data-bs-toggle="dropdown"
              className="btn btn-light rounded btn-sm font-sm"
            >
              <FontAwesomeIcon icon={faStreetView} />
            </a>
            <div className="dropdown-menu">
              {editingId === rowData.id ? (
                <a
                  className="dropdown-item edit-icon"
                  onClick={() => handleSave(rowData.id)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faSave} /> Save
                </a>
              ) : (
                <a
                  className="dropdown-item edit-icon"
                  onClick={() => handleEdit(rowData.id, rowData.key_value)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </a>
              )}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const filteredProducts = Setting?.filter((product) =>
    product.key_name?.toLowerCase().includes(globalFilter.toLowerCase())
  );


  const startItem = pageNumber * itemsPerPage + 1;
  const endItem = Math.min((pageNumber + 1) * itemsPerPage, filteredProducts.length);


  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Setting List</h1>

          <div className="search-bar">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item">Setting</li>
                <li className="breadcrumb-item active">Data</li>
              </ol>
            </nav>
            <span className="p-input-icon-left">
              <input
                type="search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search..."
                className="custom-input"
              />
            </span>
          </div>
        </div>
        <br />
        <section className="section">
          <DataTable
            value={currentSetting}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            emptyMessage=" records found"
            className="react-data-table"
          >
            {columns.map((col, i) => (
              <Column key={i} field={col.field} header={col.header} body={col.body} />
            ))}
          </DataTable>

          <div className="pagetitle">
            <div className="results-info">
              Showing {startItem} - {endItem} of {filteredProducts?.length} results
            </div>
            <ReactPaginate
              previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
              nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />

          </div>

          <SingleImageSelect
            isOpen={modalIsOpen}
            handleClose={closeModal}
            handleImageClick={handleImageClick}
            selectedImages={selectedImages}
            imageUrl={imageUrl}
            token={token}
            setImageUrl={setImageUrl}
          />
        </section>


      </main>
    </>
  );
};

export default SettingList;
