import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  AdsDropDown,
  CampaignDropDown,
  ClassificationDropDown,
  addSection,
} from "../../../../service/apiService";
import "../../../../assets/css/section.css";
import { toast } from "react-toastify";
import VideoGallery from "../../../../components/Product/VideoGallery";
import ImageGallery from "../../../../components/Product/ImageGallery";
import Select from "react-select";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch";

const SectionCreate = ({
  isOpen,
  onRequestClose,
  onSave,
  sectionId,
  onSuccess,
  limit,
}) => {
  const pageid = localStorage.getItem("pageid");
  const [formData, setFormData] = useState({});

  const [selectedValuesData, setSelectedValuesData] = useState("");
  const [adsDropdown, setAdsDropdown] = useState([]);
  const [sectionDropdownData, setSectionDropdownData] = useState([]);
  const [selectedImageMedia, setSelectedImageMedia] = useState([]);
  const [selectedVideoMedia, setSelectedVideoMedia] = useState([]);
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const [isVideoGalleryOpen, setIsVideoGalleryOpen] = useState(false);
  const [campaignData, setCampaignData] = useState([]); // if (parseInt(sectionId) === 23) {
  const [section18_Err, setSection18_Err] = useState(false);
  const [section14ImgId, setSection14ImgId] = useState(null);



  const [sec14FormData, setSec14FormData] = useState([
    { key_name: "TITLE_1", key_value: "" },
    { key_name: "IMG_1", key_value: "" },
    { key_name: "DESC_1", key_value: "" },
    { key_name: "LINK_1", key_value: "" },
    { key_name: "TITLE_2", key_value: "" },
    { key_name: "IMG_2", key_value: "" },
    { key_name: "DESC_2", key_value: "" },
    { key_name: "LINK_2", key_value: "" },
    { key_name: "TITLE_3", key_value: "" },
    { key_name: "IMG_3", key_value: "" },
    { key_name: "DESC_3", key_value: "" },
    { key_name: "LINK_3", key_value: "" },
  ]);





  const [section23ImgId, setSection23ImgId] = useState(null);
  const [sec23, setSec23] = useState([
    { key_name: "LINK", key_value: "" },
    { key_name: "BG_IMG", key_value: "" },
    { key_name: "IMG", key_value: "" },
  ]);

  const [section9Ads, setSection9Ads] = useState({
    ADS_1: null,
    ADS_2: null,
    ADS_3: null,
  });


  const token = localStorage.getItem("admin_token");
  const [singleDropDown, setSingleDropDown] = useState([]);
  const AppUrl = "https://folcon.pureinfant.in/api/files/";
  const [errorMessage, setErrorMessage] = useState("");
  const [errorWarningMessage, setErrorWarningMessage] = useState("");

  const resetState = () => {
    setFormData({});
    setSelectedImageMedia([]);
    setSelectedVideoMedia([]);
    setErrorWarningMessage("");
    setErrorMessage("");
    setSection9Ads({
      ADS_1: null,
      ADS_2: null,
      ADS_3: null,
    });
  };
  const handleCustomChange = (selectedOption) => {
    if (selectedOption.length > 2) {
      setErrorWarningMessage("You can only select up to 2 ads.");
    } else {
      const selectedValues = selectedOption
        ? selectedOption.map((option) => option.value)
        : [];
      setFormData((prevData) => ({
        ...prevData,
        ADS: selectedValues,
      }));
    }
  };
  const handleChange = (selectedOption) => {
    const selectedValues = selectedOption
      ? selectedOption?.map((option) => option.value)
      : [];
    setSelectedValuesData(selectedValues);
    setFormData((prevData) => ({
      ...prevData,
      ADS: selectedValues,
    }));
  };



  const [stopStatus, setStopStatus] = useState(0);
  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const section19_HandleChange = (selectedOption) => {
    if (selectedOption.length > 2) {
      setSection18_Err(true);
      return;
    }
    const selectedValues = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    setFormData((prevData) => ({
      ...prevData,
      ADS: selectedValues,
    }));
  };

  // {section 14 handle}
  const sec14_ImageGalleryOpen = (rowIndex) => {
    setSection14ImgId(rowIndex);
    setIsImageGalleryOpen(true);
  };

  const sec14HandleOnChange = (event, index) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      key_value: event.target.value,
    };
    setSec14FormData(updatedFormData);
  };
  const sec14_ImageGalleryHandle = (mediaUrl) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[section14ImgId] = {
      ...updatedFormData[section14ImgId],
      key_value: mediaUrl,
    };
    //     payload={
    // ...updatedFormData,

    //     }
    console.log(updatedFormData[section14ImgId], "updated");
    console.log(updatedFormData, "updated-formdata");
    setSec14FormData(updatedFormData);
  };

  const sec14_handleDeleteMedia = (index) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[index].key_value = "";
    setSec14FormData(updatedFormData);
  };

  // {  section23 handle }

  const sec23_ImageGalleryOpen = (rowIndex, keyName) => {
    setSection23ImgId({ rowIndex, keyName });
    setIsImageGalleryOpen(true);
  };

  const sec23_ImageGalleryHandle = (mediaUrl) => {
    const { keyName } = section23ImgId;
    const updatedFormData = [...sec23];
    const index = updatedFormData.findIndex(
      (item) => item.key_name === keyName
    );
    updatedFormData[index] = {
      ...updatedFormData[index],
      key_value: mediaUrl,
    };
    setSec23(updatedFormData);
  };

  const sec23HandleOnchange = (event, index) => {
    const { name, value } = event.target;
    setSec23((prevFormData) =>
      prevFormData.map((item) =>
        item.key_name === name ? { ...item, key_value: value } : item
      )
    );
  };

  const handleImageMediaSelect = (mediaUrls) => {
    const newSelectedMedia = [...selectedImageMedia, ...mediaUrls].slice(0, 12);
    setSelectedImageMedia(newSelectedMedia);
    setIsImageGalleryOpen(false);
  };

  const handleVideoMediaSelect = (mediaUrls) => {
    setSelectedVideoMedia(
      limit === 1 ? [mediaUrls[0]] : [...selectedVideoMedia, ...mediaUrls]
    );
    setIsVideoGalleryOpen(false);
  };

  const handleDeleteMedia = (type, index) => {
    if (type === "image") {
      setSelectedImageMedia(selectedImageMedia.filter((_, i) => i !== index));
    } else if (type === "video") {
      setSelectedVideoMedia(selectedVideoMedia.filter((_, i) => i !== index));
    }
  };

  // console.log(Object.keys(formData.ADS))

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (parseInt(sectionId) === 3 && formData?.ADS?.length !== 2) {
      setErrorMessage("Please select 2 ads.");
      return;
    }
    if (parseInt(sectionId) === 1 && formData?.ADS?.length < 3) {
      setErrorMessage("Please select more then 3 ads.");
      return;
    }

    if (parseInt(sectionId) === 20 && formData?.SPIN !== 1) {
      // setStopStatus(1);
      setErrorMessage("Must Select Active Spinner");
      return;
    }
    if (parseInt(sectionId) === 17 && formData?.Notification !== 1) {
      // setStopStatus(1);
      setErrorMessage("Must Select Active Notification");
      return;
    }
    if (parseInt(sectionId) === 21 && formData?.BLOG !== 1) {
      // setStopStatus(1);
      setErrorMessage("Must Select Active Blogs");
      return;
    }
    if (parseInt(sectionId) === 14 && formData?.ADS?.length !== 1) {
      setErrorMessage("Please select 1 ads.");
      return;
    }


    if (parseInt(sectionId) === 11 && formData?.ADS?.length !== 3) {
      setErrorMessage("Please select 3 ads.");
      return;
    }
    if (parseInt(sectionId) === 7 && formData?.BRAND !== 1) {
      setErrorMessage("Must Select Active Toggle");
      return;
    }
    if (parseInt(sectionId) === 4 && formData?.CLASSIFICATION == undefined) {
      setErrorMessage("Must Select classification");
      return;
    }
    if (parseInt(sectionId) === 8 && formData?.CLASSIFICATION == undefined) {
      setErrorMessage("Must Select classification");
      return;
    }
    const sectionValue = Object.keys(formData).map((key) => ({
      key_name: key === "CLASSIFICATION" ? "CLASSIFI" : key,
      key_value: Array.isArray(formData[key])
        ? formData[key].join(",")
        : String(formData[key]),
    }));
    let payload = {};
    if (parseInt(sectionId) == 2) {
      payload = {
        page_id: parseInt(pageid),
        section_id: parseInt(sectionId),
        section_value: sectionValue.filter(
          (item) => item.key_name !== "section_id"
        ),
      };
    }
    if (parseInt(sectionId) !== 2) {
      payload = {
        page_id: parseInt(pageid),
        section_id: parseInt(sectionId),
        section_value: sectionValue.filter(
          (item) => item.key_name !== "section_id"
        ),
      };

      if (parseInt(sectionId) == 3) {
        payload = {
          page_id: parseInt(pageid),
          section_id: parseInt(sectionId),
          section_value: sectionValue.filter(
            (item) => item.key_name !== "section_id"
          ),
        };
      }

      if (parseInt(sectionId) === 23) {
        console.log(sec23);
        payload = {
          page_id: parseInt(pageid),
          section_id: parseInt(sectionId),
          section_value: sec23,
        };
      }
      if (selectedImageMedia.length > 0) {
        payload.section_value.push({
          key_name: "File",
          key_value: selectedImageMedia.join(","),
        });
      }
      if (selectedVideoMedia.length > 0) {
        payload.section_value.push({
          key_name: "File",
          key_value: selectedVideoMedia.join(","),
        });
      }
    } else if (parseInt(sectionId) === 21) {
      payload = {
        page_id: parseInt(pageid),
        section_id: parseInt(sectionId),
        section_value: sec14FormData,
      };
    }

    if (parseInt(sectionId) === 23) {
      payload.section_value = payload.section_value.filter(
        (item) => item?.key_name !== "File"
      );
    }

    if (parseInt(sectionId) === 1) {
      console.log(sectionId);
      payload = {
        page_id: parseInt(pageid),
        section_id: parseInt(sectionId),
        section_value: [
          ...sec14FormData,
          { key_name: "ADS", key_value: formData.ADS?.toString() },
        ],
      };
    }

    if (stopStatus === 0) {
      try {
        const response = await addSection(token, payload);
        if (response.status_code === 200) {
          toast.success("Section created successfully!");
          fetchAdsDropdown();
          setSelectedVideoMedia([]);
          setSelectedImageMedia([]);
          onSuccess(true);
          setFormData({});
          setErrorMessage();
          setSection9Ads({});

          setSelectedImageMedia([]);
          setSelectedVideoMedia([]);
          if (parseInt(sectionId) === 23) {
            setSec23([
              { key_name: "LINK", key_value: "" },
              { key_name: "BG_IMG", key_value: "" },
              { key_name: "IMG", key_value: "" },
            ]);
          }
          if (parseInt(sectionId) === 1) {
            setSec14FormData([
              { key_name: "TITLE_1", key_value: "" },
              { key_name: "IMG_1", key_value: "" },
              { key_name: "DESC_1", key_value: "" },
              { key_name: "LINK_1", key_value: "" },
              { key_name: "TITLE_2", key_value: "" },
              { key_name: "IMG_2", key_value: "" },
              { key_name: "DESC_2", key_value: "" },
              { key_name: "LINK_2", key_value: "" },
              { key_name: "TITLE_3", key_value: "" },
              { key_name: "IMG_3", key_value: "" },
              { key_name: "DESC_3", key_value: "" },
              { key_name: "LINK_3", key_value: "" },
              { key_name: "ADS", key_value: "" },
            ]);
          }
        } else {
          setSelectedVideoMedia([]);
          setSelectedImageMedia([]);
          onSuccess(true);
          setFormData({});
          setErrorMessage();
        }
      } catch (error) {
        toast.error("Section creation failed. Please try again.");
      }
      onSave(formData);
      onRequestClose();
    }
  };

  useEffect(() => {
    fetchAdsDropdown();
    fetchClassificationDropdown();
    fetchAdsDropdownSingle();
    campaignDropdown();
  }, []);

  const fetchAdsDropdown = async () => {
    const response = await AdsDropDown(token);
    setAdsDropdown(
      response.data?.map((option) => ({
        value: option.id,
        label: option.name.toUpperCase(),
      }))
    );
  };
  const campaignDropdown = async () => {
    const response = await CampaignDropDown(token);
    setCampaignData(
      response.data?.map((option) => ({
        value: option.campaign_id,
        label: option.campaign_name.toUpperCase(),
      }))
    );
  };
  const fetchAdsDropdownSingle = async () => {
    const response = await AdsDropDown(token);
    setSingleDropDown(response.data);
  };

  const fetchClassificationDropdown = async () => {
    const classifications = await ClassificationDropDown(token);
    if (classifications?.data) {
      setSectionDropdownData(
        classifications?.data.map((option) => ({
          value: option.classification_id,
          label: option.classification_name.toUpperCase(),
        }))
      );
    }
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      section_id: sectionId,
    }));
  }, [sectionId]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Section"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: "15px",
          padding: "20px",
          maxWidth: "1500px",
          minWidth: "1500px",
          maxHeight: "700px",
          minHeight: "800px",
          overflowY: "auto",
          boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
          border: "2px solid #63E6BE",
        },
      }}
    >
      <div className="modal-header">
        <h2>Add Section</h2>
        <button
          onClick={() => {
            resetState(); // Reset state when the close button is clicked
            onRequestClose();
          }}
        >
          Close
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {/* {[5].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Link</label>
            <input
              id="titleInput1"
              type="text"
              name="LINK"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
          </div>
        )} */}
        {[6].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Discription</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Button Name</label>
            <input
              id="titleInput1"
              type="text"
              name="BTN"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Link</label>
            <input
              id="titleInput1"
              type="text"
              name="LINK"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
          </div>
        )}
        {[2].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title:1</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Discription:1</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Title:2</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Discription:2</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Title:3</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Discription:3</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Title:4</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_3"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Discription:4</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC_3"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
          </div>
        )}

        {[1].includes(parseInt(sectionId)) && (
          <div style={{ paddingBottom: "20px" }}>
            {/* First set */}
            <div
              className="conditional-container"
              style={{ marginBottom: "10px" }}
            >
              <div className="dropdown-container">
                <div className="input-container form-group">
                  <label htmlFor={`titleInput1`}>Title 1</label>
                  <input
                    id={`titleInput1`}
                    type="text"
                    name={sec14FormData[0]?.key_name}
                    value={sec14FormData[0]?.key_value}
                    onChange={(event) => sec14HandleOnChange(event, 0)}
                    className="form-control"
                    placeholder={`Enter title 1`}
                  />
                </div>
                <div className="input-container form-group">
                  <label htmlFor={`titleInput1`}>Description : 1</label>
                  <input
                    id={`titleInput1`}
                    type="text"
                    name={sec14FormData[2]?.key_name}
                    value={sec14FormData[2]?.key_value}
                    onChange={(event) => sec14HandleOnChange(event, 2)}
                    className="form-control"
                    placeholder={`Enter title 1`}
                  />
                </div>
                <div className="input-container form-group">
                  <label htmlFor={`titleInput1`}>LINK : 1</label>
                  <input
                    id={`titleInput1`}
                    type="text"
                    name={sec14FormData[3]?.key_name}
                    value={sec14FormData[3]?.key_value}
                    onChange={(event) => sec14HandleOnChange(event, 3)}
                    className="form-control"
                    placeholder={`Enter title 1`}
                  />
                </div>
              </div>
              <div className="image-gallery-container">
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() => sec14_ImageGalleryOpen(1)}
                >
                  Image Gallery 1
                </button>
              </div>
              {sec14FormData[1]?.key_value && (
                <div className="media-preview">
                  <div className="media-items">
                    <div className="media-item">
                      <img
                        src={AppUrl + sec14FormData[1]?.key_value}
                        alt="Selected"
                        style={{ width: "150px", height: "200px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{ marginLeft: "30px" }}
                        onClick={() => sec14_handleDeleteMedia(1)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Second set */}
            <div
              className="conditional-container"
              style={{ marginBottom: "10px" }}
            >
              <div className="dropdown-container">
                <div className="input-container form-group">
                  <label htmlFor={`titleInput2`}>Title 2</label>
                  <input
                    id={`titleInput2`}
                    type="text"
                    name={sec14FormData[4]?.key_name}
                    value={sec14FormData[4]?.key_value}
                    onChange={(event) => sec14HandleOnChange(event, 4)}
                    className="form-control"
                    placeholder={`Enter title 2`}
                  />
                </div>
                <div className="input-container form-group">
                  <label htmlFor={`titleInput1`}>Description : 2</label>
                  <input
                    id={`titleInput1`}
                    type="text"
                    name={sec14FormData[6]?.key_name}
                    value={sec14FormData[6]?.key_value}
                    onChange={(event) => sec14HandleOnChange(event, 6)}
                    className="form-control"
                    placeholder={`Enter title 1`}
                  />
                </div>
                <div className="input-container form-group">
                  <label htmlFor={`titleInput1`}>LINK : 2</label>
                  <input
                    id={`titleInput1`}
                    type="text"
                    name={sec14FormData[7]?.key_name}
                    value={sec14FormData[7]?.key_value}
                    onChange={(event) => sec14HandleOnChange(event, 7)}
                    className="form-control"
                    placeholder={`Enter title 1`}
                  />
                </div>
              </div>
              <div className="image-gallery-container">
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() => sec14_ImageGalleryOpen(5)}
                >
                  Image Gallery 2
                </button>
              </div>
              {sec14FormData[5]?.key_value && (
                <div className="media-preview">
                  <div className="media-items">
                    <div className="media-item">
                      <img
                        src={AppUrl + sec14FormData[5]?.key_value}
                        alt="Selected"
                        style={{ width: "150px", height: "200px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{ marginLeft: "30px" }}
                        onClick={() => sec14_handleDeleteMedia(5)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Third set */}
            <div
              className="conditional-container"
              style={{ marginBottom: "10px" }}
            >
              <div className="dropdown-container">
                <div className="input-container form-group">
                  <label htmlFor={`titleInput3`}>Title 3</label>
                  <input
                    id={`titleInput3`}
                    type="text"
                    name={sec14FormData[8]?.key_name}
                    value={sec14FormData[8]?.key_value}
                    onChange={(event) => sec14HandleOnChange(event, 8)}
                    className="form-control"
                    placeholder={`Enter title 3`}
                  />
                </div>
                <div className="input-container form-group">
                  <label htmlFor={`titleInput1`}>Description : 3</label>
                  <input
                    id={`titleInput1`}
                    type="text"
                    name={sec14FormData[10]?.key_name}
                    value={sec14FormData[10]?.key_value}
                    onChange={(event) => sec14HandleOnChange(event, 10)}
                    className="form-control"
                    placeholder={`Enter title 1`}
                  />
                </div>
                <div className="input-container form-group">
                  <label htmlFor={`titleInput1`}>LINK : 3</label>
                  <input
                    id={`titleInput1`}
                    type="text"
                    name={sec14FormData[11]?.key_name}
                    value={sec14FormData[11]?.key_value}
                    onChange={(event) => sec14HandleOnChange(event, 11)}
                    className="form-control"
                    placeholder={`Enter title 1`}
                  />
                </div>
              </div>
              <div className="image-gallery-container">
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() => sec14_ImageGalleryOpen(9)}
                >
                  Image Gallery 3
                </button>
              </div>
              {sec14FormData[9]?.key_value && (
                <div className="media-preview">
                  <div className="media-items">
                    <div className="media-item">
                      <img
                        src={AppUrl + sec14FormData[9]?.key_value}
                        alt="Selected"
                        style={{ width: "150px", height: "200px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{ marginLeft: "30px" }}
                        onClick={() => sec14_handleDeleteMedia(9)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 6) {
                  handleChange(selectedOption);
                  setStopStatus(0);
                  setErrorMessage("");
                } else {
                  setStopStatus(1);
                  setErrorMessage("You can select a maximum of 6 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}

        {/* {section 9} */}
        {[4].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <select
              id="adsDropdown2"
              name="ADS"
              value={formData.ADS || ""}
              onChange={handleUpdateChange}
              className="dropdown"
            >
              <option value="">Select an Ads</option>
              {singleDropDown.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name.toUpperCase()}
                </option>
              ))}
            </select>
            <div>
              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={sectionDropdownData.filter(
                  (option) => option.value === formData.CLASSIFICATION
                )}
                onChange={(selectedOption) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={sectionDropdownData}
              />
            </div>
          </div>
        )}
        {[13].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Title 1</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description 1</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description 2</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <select
              id="adsDropdown2"
              name="ADS"
              value={formData.ADS || ""}
              onChange={handleUpdateChange}
              className="dropdown"
            >
              <option value="">Select an Ads</option>
              {singleDropDown.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        )}
        {[12].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Title 1</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description 1</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description 2</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <select
              id="adsDropdown2"
              name="ADS"
              value={formData.ADS || ""}
              onChange={handleUpdateChange}
              className="dropdown"
            >
              <option value="">Select an Ads</option>
              {singleDropDown.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        )}
        {[7].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            {/* {toggle} */}
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">Brand Toggle</label>
              <ToggleSwitch
                checked={formData.BRAND === 1}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    BRAND: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>
        )}
        {[8].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div>
              <label htmlFor="titleInput">Title</label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title here"
              />
              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={sectionDropdownData.filter(
                  (option) => option.value === formData.CLASSIFICATION
                )}
                onChange={(selectedOption) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={sectionDropdownData}
              />
            </div>
          </div>
        )}
        {[9].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div>
              <label htmlFor="titleInput">Title</label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title here"
              />
              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={sectionDropdownData.filter(
                  (option) => option.value === formData.CLASSIFICATION
                )}
                onChange={(selectedOption) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={sectionDropdownData}
              />
            </div>
          </div>
        )}
        {/* {[7].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <div>
            <label htmlFor="adsDropdown">Ads</label>
            <select
              id="adsDropdown2"
              name="ADS"
              value={formData.ADS || ""}
              onChange={handleUpdateChange}
              className="dropdown"
            >
              <option value="">Select an Ads</option>
              {singleDropDown.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name.toUpperCase()}
                </option>
              ))}
            </select>
              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={sectionDropdownData.filter(
                  (option) => option.value === formData.CLASSIFICATION
                )}
                onChange={(selectedOption) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={sectionDropdownData}
              />
            </div>
          </div>
        )} */}
        {[3].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">News Letter Title</label>
            <input
              id="titleInput1"
              type="text"
              name="NL_TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">News Letter Description </label>
            <input
              id="titleInput1"
              type="text"
              name="NL_DESC"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 2) {
                  handleChange(selectedOption);
                  setStopStatus(0);
                  setErrorMessage("");
                } else {
                  setStopStatus(1);
                  setErrorMessage("You can select a maximum of 2 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}

        {/* {[4].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title:</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Discription</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC" 
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Button Name</label>
            <input
              id="titleInput1"
              type="text"
              name="BTN"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
          </div>
        )} */}
        {/* {
          [3].includes(parseInt(sectionId)) && (
            <div className="dropdown-container">
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={formData.TITLE_1}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
              </div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 2: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_2"
                  value={formData.TITLE_2}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
              </div>

              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={sectionDropdownData.filter(
                  (option) => option.value === formData.CLASSIFICATION
                )}
                onChange={(selectedOption) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={sectionDropdownData}
              />
            </div>
          )
        } */}
        {/* section 15 */}
        {[15].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title:1</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />

            <label htmlFor="adsDropdown2">PreOrder</label>
            <ToggleSwitch
              checked={formData.PRE_ORDER === 1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  PRE_ORDER: e.target.checked ? 1 : 0,
                }))
              }
            />
          </div>
        )}
        {[11].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div className="input-container form-group">
              <label htmlFor="titleInput">Title: </label>
              <input
                id="titleInput"
                type="text"
                name="TITLE"
                value={formData.TITLE}
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 3) {
                  handleChange(selectedOption);
                  setStopStatus(0);
                  setErrorMessage("");
                } else {
                  setStopStatus(1);
                  setErrorMessage("You can select a maximum of 3 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}
        {/* {
          [6].includes(parseInt(sectionId)) && (
            <div className="dropdown-container">
              {limit === 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => setIsImageGalleryOpen(true)}
                  >
                    Image Gallery
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => setIsVideoGalleryOpen(true)}
                  >
                    Video Gallery
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      setIsImageGalleryOpen(selectedImageMedia.length === 0)
                    }
                    disabled={selectedImageMedia.length > 0}
                  >
                    Image Gallery
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      setIsVideoGalleryOpen(selectedVideoMedia.length === 0)
                    }
                    disabled={selectedVideoMedia.length > 0}
                  >
                    Video Gallery
                  </button>
                </>
              )}
              <label htmlFor="titleInput">Title</label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title here"
              />
              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>

              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={sectionDropdownData.filter(
                  (option) => option.value === formData.CLASSIFICATION
                )}
                onChange={(selectedOption) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={sectionDropdownData}
              />
            </div>
          )
        } */}
        {[18].includes(parseInt(sectionId)) && (
          <>
            <div className="dropdown-container">
              {limit === 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => setIsImageGalleryOpen(true)}
                  >
                    Image Gallery
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => setIsVideoGalleryOpen(true)}
                  >
                    Video Gallery
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      setIsImageGalleryOpen(selectedImageMedia.length === 0)
                    }
                    disabled={selectedImageMedia.length > 0}
                  >
                    Image Gallery
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      setIsVideoGalleryOpen(selectedVideoMedia.length === 0)
                    }
                    disabled={selectedVideoMedia.length > 0}
                  >
                    Video Gallery
                  </button>
                </>
              )}
            </div>

            <div className="dropdown-container">
              {errorWarningMessage.length > 0 ? (
                <div className="Error-Msg">{errorWarningMessage}</div>
              ) : (
                <p className="DeFault-Msg"> Select an Ad</p>
              )}
              <label htmlFor="adsDropdown">Ads</label>
              <Select
                id="adsDropdown"
                name="ADS"
                value={
                  formData.ADS
                    ? adsDropdown.filter((option) =>
                      formData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={handleCustomChange}
                options={adsDropdown}
                isMulti
              />
              <div>
                <label htmlFor="adsDropdown">Text Feild</label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={formData.TITLE}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
              </div>
            </div>
          </>
        )}
        {[19].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <div>
              <label htmlFor="adsDropdown">Ads</label>
              <Select
                id="adsDropdown"
                name="ADS"
                value={
                  formData.ADS
                    ? adsDropdown.filter((option) =>
                      formData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={section19_HandleChange}
                options={adsDropdown}
                isMulti
              />
            </div>
            {section18_Err ? (
              <p style={{ color: "red", fontSize: "14px", paddingTop: "10px" }}>
                You can only select two ads!
              </p>
            ) : null}
          </div>
        )}

        {[10].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="Campaign">CAMPAIGN</label>
            <Select
              id="CampaignDropdown"
              name="CAMP"
              value={campaignData?.filter(
                (option) => option.value === formData.CAMP
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CAMP: selectedOption.value,
                }))
              }
              options={campaignData}
            />
          </div>
        )}
        {[16].includes(parseInt(sectionId)) && (
          <div className="conditional-container">
            <div className="dropdown-container">
              <div className="input-container form-group">
                <label htmlFor="titleInput1">Title 1: </label>
                <input
                  id="titleInput1"
                  type="text"
                  name="TITLE_1"
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title 1"
                />
              </div>
              <div className="input-container form-group">
                <label htmlFor="titleInput2">Title 2: </label>
                <input
                  id="titleInput2"
                  type="text"
                  name="TITLE_2"
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title 2"
                />
              </div>
            </div>
            <div className="image-gallery-container">
              {limit === 0 ? (
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() => setIsImageGalleryOpen(true)}
                >
                  Image Gallery
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() =>
                    setIsImageGalleryOpen(selectedImageMedia.length === 0)
                  }
                  disabled={selectedImageMedia.length > 0}
                >
                  Image Gallery
                </button>
              )}
            </div>
          </div>
        )}
        {/* {[13].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />

            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />
          </div>
        )} */}

        {/* section 20 */}
        {[20].includes(parseInt(sectionId)) && (
          <div>
            <div className="input-container form-group">
              <label htmlFor="titleInput1">Title 1: </label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title 1"
              />
            </div>
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">Active Spinner:</label>
              <ToggleSwitch
                checked={formData.SPIN === 1}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    SPIN: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>
        )}

        {[17].includes(parseInt(sectionId)) && (
          <div>
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">Active Notification:</label>
              <ToggleSwitch
                checked={formData.Notification === 1}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    Notification: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>
        )}
        {[14].includes(parseInt(sectionId)) && (
          <div>
            <div className="input-container form-group">
              <label htmlFor="titleInput1">Title: </label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title 1"
              />
            </div>
            <div className="input-container form-group">
              <label htmlFor="adsDropdown">Ads</label>
              <select
                id="adsDropdown2"
                name="ADS"
                value={formData.ADS || ""}
                onChange={handleUpdateChange}
                className="dropdown"
              >
                <option value="">Select an Ads</option>
                {singleDropDown.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        {/* section 21 */}
        {[5].includes(parseInt(sectionId)) && (
          <div>
            <div className="input-container form-group">
              <label htmlFor="titleInput1">Title: </label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title 1"
              />
            </div>
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">Active Blogs:</label>
              <ToggleSwitch
                checked={formData.BLOG === 1}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    BLOG: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>
        )}
        {/* section 22 */}
        {[22].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title 1</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description 1</label>
            <textarea
              id="titleInput2"
              name="DEC_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter another title here"
            />
            <label htmlFor="titleInput">Title 2</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description 2</label>
            <textarea
              id="titleInput2"
              name="DEC_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter another title here"
            />
            <label htmlFor="titleInput">Title 3</label>

            <input
              id="titleInput1"
              type="text"
              name="TITLE_3"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description 3</label>
            <textarea
              id="titleInput2"
              name="DEC_3"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter another title here"
            />
            <label htmlFor="titleInput">Title 4</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_4"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description 4</label>
            <textarea
              id="titleInput2"
              name="DEC_4"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter another title here"
            />
            <label htmlFor="titleInput">Title 5</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_5"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description 5</label>
            <textarea
              id="titleInput2"
              name="DEC_5"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter another title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <select
              id="adsDropdown2"
              name="ADS"
              value={formData.ADS || ""}
              onChange={handleUpdateChange}
              className="dropdown"
            >
              <option value="">Select an Ads</option>
              {singleDropDown.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name.toUpperCase()}
                </option>
              ))}
            </select>
            <label htmlFor="titleInput">Link</label>
            <input
              id="titleInput1"
              type="text"
              name="LINK"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Button Name</label>
            <input
              id="titleInput1"
              type="text"
              name="BUTTON_NAME"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
          </div>
        )}
        {/* section 23 */}
        {[23].includes(parseInt(sectionId)) && (
          <div>
            <div className="input-container form-group">
              <label htmlFor="titleInput">Link:</label>
              <input
                id="titleInput"
                type="text"
                name="LINK"
                onChange={(event) => sec23HandleOnchange(event)}
                className="form-control"
                placeholder="Enter Link ...."
              />
            </div>

            {/* BG_IMG */}
            <div className="dropdown-container">
              <button
                type="button"
                className="btn btn-secondary mb-3"
                onClick={() => sec23_ImageGalleryOpen(1, "BG_IMG")}
              >
                Image Gallery - BG_IMG
              </button>
              {sec23[1].key_value && (
                <div className="media-preview">
                  <div className="media-items">
                    <div className="media-item">
                      <img
                        src={AppUrl + sec23[1].key_value}
                        alt="Selected"
                        style={{ width: "150px", height: "200px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{ marginLeft: "30px" }}
                        onClick={() =>
                          sec23HandleOnchange({
                            target: { name: "BG_IMG", value: "" },
                          })
                        }
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* IMG */}
            <div className="dropdown-container">
              <button
                type="button"
                className="btn btn-secondary mb-3"
                onClick={() => sec23_ImageGalleryOpen(2, "IMG")}
              >
                Image Gallery - IMG
              </button>
              {sec23[2].key_value && (
                <div className="media-preview">
                  <div className="media-items">
                    <div className="media-item">
                      <img
                        src={AppUrl + sec23[2].key_value}
                        alt="Selected"
                        style={{ width: "150px", height: "200px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{ marginLeft: "30px" }}
                        onClick={() =>
                          sec23HandleOnchange({
                            target: { name: "IMG", value: "" },
                          })
                        }
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* ************************************************************************************************************************************* */}
        <div className="media-preview-container">
          {selectedImageMedia.length > 0 && (
            <div className="media-preview">
              <h3>Selected Images</h3>
              <div className="media-items">
                {selectedImageMedia.map((mediaUrl, index) => (
                  <div key={index} className="media-item">
                    <img
                      src={AppUrl + mediaUrl}
                      alt="Selected"
                      width={"250px"}
                    />
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDeleteMedia("image", index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {selectedVideoMedia.length > 0 && (
            <div className="media-preview">
              <h3>Selected Videos</h3>
              <div className="media-items">
                {selectedVideoMedia.map((mediaUrl, index) => (
                  <div key={index} className="media-item">
                    <video
                      src={AppUrl + mediaUrl}
                      controls
                      width={"250px"}
                      height={"200px"}
                    />
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDeleteMedia("video", index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <button type="submit" className="save-button">
          Save
        </button>
      </form>
      <ImageGallery
        isOpen={isImageGalleryOpen}
        onRequestClose={() => setIsImageGalleryOpen(false)}
        onMediaSelect={handleImageMediaSelect}
        selectedMedia={selectedImageMedia}
        setSelectedMedia={setSelectedImageMedia}
        limit={3}
        sectionId={sectionId}
        sec14_ImageGalleryHandle={sec14_ImageGalleryHandle}
        sec23_ImageGalleryHandle={sec23_ImageGalleryHandle}
      />
      <VideoGallery
        isOpen={isVideoGalleryOpen}
        onRequestClose={() => setIsVideoGalleryOpen(false)}
        onMediaSelect={handleVideoMediaSelect}
        selectedMedia={selectedVideoMedia}
        setSelectedMedia={setSelectedVideoMedia}
        limit={limit}
      />
    </Modal>
  );
};

export default SectionCreate;
