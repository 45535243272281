
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import adminLogo from '../../assets/images/adminlogonew.png';
import bgImage from '../../assets/images/rm301-nunny-19.jpg';
import slideImage1 from '../../assets/images/Ecommerce web page-pana.png';
import slideImage2 from '../../assets/images/Business Plan-amico.svg';
import slideImage3 from '../../assets/images/Ecommerce web page-amico.svg';

import { validateEmail, validatePassword } from '../../validation/CustomValidation';
import { AdminSignIn } from '../../service/apiService';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import { Form } from 'react-bootstrap';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const AdminLogin = ({ setIsAdminAuthenticated, setProductKeyState }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [slideImage1, slideImage2, slideImage3];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [slides.length]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(userData.email);
    const passwordError = validatePassword(userData.password);

    setValidationErrors({
      email: emailError,
      password: passwordError,
    });

    if (!passwordError && !emailError) {
      try {
        const response = await AdminSignIn(userData);
        if (response.status_code === 200) {
          localStorage.setItem('admin_token', response.token.token);
          setIsAdminAuthenticated(true);
          toast.success(response.message);
          localStorage.setItem('productKeyState', false);

          navigate('/admin/dashboard', { replace: true });
        } else {
          setErrorMessage(response.message);
        }
      } catch (error) {
        if (error.response.status === 403) {
          setProductKeyState(true);
          navigate('/product-key');
        }
        setErrorMessage(error.response.data.message);
      }
    }
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="admin-login-container">
      <div className="admin-login-form">

        <div className="card-body" style={{ marginTop: '7rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={adminLogo} alt="Admin Logo" className="admin-logo" />
          <br />
          <div className="d-flex justify-content-center align-items-center my-3">
            <p>Welcome to the ReactAdmin
            </p>
          </div>
          <p className="text-center  text-muted">Manage your e-commerce platform with ease. Log in to access your dashboard,<br /> oversee operations, and keep your store running smoothly. <br />We're here to support your success—let's get started!</p> <br />

          <h2 className="card-title text-center"> <strong>Sign in</strong></h2>
          <br />
          <form onSubmit={handleSubmit} className="text-center">
            <Form.Text className="text-danger bold">{errorMessage}</Form.Text>
            <div className="mb-3">
              <TextField
                type="email"
                name="email"
                className="form-control"
                id="email"
                label="Email"

                onChange={handleChange}
                value={userData.email}
                variant="outlined"
                fullWidth
                margin="normal"

                sx={{
                  width: '40ch',
                  background: 'white',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 0, // Removes the rounded corners
                  },
                }}
              />

              {validationErrors.email && (
                <Form.Text className="text-danger">{validationErrors.email}</Form.Text>
              )}
            </div>
            <div className="mb-3">
              <FormControl sx={{ width: '40ch', background: 'white' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={userData.password}
                  onChange={handleChange}
                  className="Paasword"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  name="password"
                  fullWidth
                  sx={{
                    width: '45ch',
                    background: 'white',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 0, // Removes the rounded corners
                    },
                  }}
                />
              </FormControl>
              {validationErrors.password && (
                <Form.Text className="text-danger">{validationErrors.password}</Form.Text>
              )}
            </div>
            <br />
            <button className="btn btn-primary" type="submit" name="login" style={{ width: '50%', borderRadius: "0px" }}>Login</button>
          </form>
        </div>
        <p className="text-muted">© 2024 ReactAdmin. All Rights Reserved. Powered by Folcon Engine..</p>
      </div>
      <div className="admin-login-slider" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="slider-container">
          {slides.map((slide, index) => (
            <img
              key={index}
              src={slide}
              alt={`Slide ${index}`}
              className={`slide ${index === currentSlide ? 'active' : ''}`}
            />
          ))}
        </div>
        <div className="dots">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
        <div className="slider-text">
          <h2>Unlock Your Sales Introducing Our </h2>
          <h2>Dynamic Sales Dashboard</h2>
          <p>Elevate Sales Performance: Streamline Insights with Our Dynamic Dashboard <br />Your Gateway to Revenue Growth</p>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;


