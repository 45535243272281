import React from "react";
import AdminFooter from "../pages/views/Dashboard/AdminFooter";
import AdminHeader from "../pages/views/Dashboard/AdminHeader";
import SideBar from "../pages/views/Dashboard/SideBar";
import MainDashboard from "../pages/views/Dashboard/MainDashboard";


const Dashboard = () => {
    return (
        <div>
            <AdminHeader />
            <main className="main-wrap">
                <SideBar />
            </main>
            <AdminFooter />
        </div>
    );
}

export default Dashboard;