
export const validateFullName = (fullName) => {
  if (!fullName) {
    return "Please provide a full name.";
  }
  if (!/^[a-zA-Z.]+$/.test(fullName)) {
    return "Full name can only contain letters, dots, and cannot start or end with a dot.";
  }
  return "";
};


export const validateEmail = (email) => {
  if(!email) {
      return "Please provide an email address.";
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return "Please provide a valid email address.";
  }
  if (/[^a-zA-Z0-9@._-]/g.test(email)) {
    return "Email contains special characters. Please remove them.";
  }
  return "";
};

export const validatePhoneNo = (phoneNo) => {
  if (!phoneNo) {
    return "Please provide a phone number.";
  }
  if (!phoneNo || phoneNo.length !== 10 || isNaN(phoneNo)) {
    return "Please provide a valid 10-digit phone number.";
  }
  if (/[^0-9]/g.test(phoneNo)) {
    return "Invalid Phone Number. Only digits are allowed.";
  }
  return "";
};

export const validatePassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  if (!password) {
    return "Please provide a password.";
  }
  // if (!regex.test(password)) {
  //   return "Password must include uppercase, lowercase, digit, special character.";
  // }
  return "";
};

export const validateConfirmPassword = (confirmPassword, password) => {
  if (confirmPassword !== password) {
    return "Passwords do not match.";
  }
  return "";
};
export const ValidateProductName = (productName) => {
  if (!productName) {
    return "Please provide a product name.";
  }
  return "";
};
export const ValidateSku = (sku) => {
  if (!sku) {
    return "Please provide a sku.";
  }
  return "";
};
export const ValidatePrice = (price) => {
  if (!price) {
    return "Please provide a price.";
  }
  if(price){
      const regex = /^-?\d+(\.\d+)?$/;
      if (!regex.test(price)) {
        return "Please provide a valid price , It accept number and decimal.";
      }
  }
};
export const ValidateQuantity = (quantity) => {
  if (!quantity) {
    return "Please provide a quantity.";
  }
  if(quantity){
      const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (!regex.test(quantity)) {
        return "Please provide a valid quantity , It accept only number.";
      }
  }
};
export const ValidateUnit = (unit) => {
  if (!unit) {
    return "Please provide a unit.";
  }
  if(unit){
      const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (!regex.test(unit)) {
        return "Please provide a valid unit , It accept only number.";
      }
  }
};
export const ValidateWeight = (weight) => {
  if (!weight) {
    return "Please provide a weight.";
  }
  if(weight){
      const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (!regex.test(weight)) {
        return "Please provide a valid weight , It accept only number.";
      }
  }
};
export const ValidateVariant = (variant) => {
  if (!variant) {
    return "Please provide a variant.";
  }
  if(variant){
      const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (!regex.test(variant)) {
        return "Please provide a valid variant , It accept only number.";
      }
  }
};
export const ValidateTax = (tax) => {
  if (!tax) {
    return "Please select a tax.";
  }
  return "";
};
export const ValidateBrand = (brand) => {
  if (!brand) {
    return "Please select a brand.";
  }
  return "";
};
export const ValidateCategory = (category) => {
  if (!category) {
    return "Please select a category.";
  }
  return "";
};
export const ValidateCoupenCode = (coupenCode) => {
  if (!coupenCode) {
    return "Please provide a coupen code.";
  }
  return "";
};
export const ValidateLastName = (last_name) =>{
if (!last_name){
  return "Please provide a last name.";
}
if (!/^[a-zA-Z.]+$/.test(last_name)) {
  return "Last name can only contain letters, dots, and cannot start or end with a dot.";
}
return "";
}
export  const validateAddress = (address) => {
if(!address){
  return "Please provide a address."
}
return "";
}
export const validateCity = (city) =>{
if(!city){
  return "Please provide a city."
}
if (/[^a-zA-Z\s]+/g.test(city)) {
  return "Please provide a valid city name.";
}
return "";
}
export const validateState = (state) =>{
if(!state){
  return "Please provide a state."
}
if (/[^a-zA-Z\s]+/g.test(state)) {
  return "Please provide a valid state name.";
}
return "";
}
export const validateSlug = (slug) =>{
  if(!slug){
    return "Please provide a slug."
  }
  return "";
  }
export const validateCountry = (country) =>{
if(!country){
  return "Please provide a country."
}
if (/[^a-zA-Z\s]+/g.test(country)) {
  return "Please provide a valid state name.";
}
return "";
}
export const validatePinCode = (pincode) => {
if(!pincode){
  return "Please provide a pincode."
}
if (/[^0-9]+/g.test(pincode)) {
  return "Please provide a valid pincode.";
}
if (pincode.length !== 6) { 
  return "Please provide a valid pincode of 6 digits."
}
return "";
}
export const validateOtp = (otp) => {
if(!otp){
  return "Please provide a otp."
}
if (/[^0-9]+/g.test(otp)) {
  return "Please provide a valid otp ,otp contain only number .";
}
if (otp.length !== 4) { 
  return "Please provide a valid otp of 6 digits."
}
return "";
}
export const validateReturnDate = (returnDate) => {
  if (!returnDate) {
    return "Please provide a return date.";
  }
  return "";  
}
export const ValidateCategoryName = (categoryName) => {
  if (!categoryName) {
    return "Please provide a category name.";
  }
  if (!/^[a-zA-Z\s.]+$/i.test(categoryName)) {
    return "Category name can only contain letters, spaces and dots.";
  }
  return "";  
} 
export const ValidateDiscriptionName = (description) => {
  if (!description) {
    return "Please provide a description.";
  }
  if (!/^[a-zA-Z.]+$/.test(description)) {
    return "Description name can only contain letters.";
  }
  return "";  
} 


export const ValidateBrandName = (productName) => {
  if (!productName) {
    return "Please provide a Brand name.";
  }
  return "";
};
export const validateStock = (stock) => {
  if (!stock) {
    return "Please provide a stock.";
  }
  if(stock){
      const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (!regex.test(stock)) {
        return "Please provide a valid stock , It accept only number.";
      }
  }
  return "";
}
export const returnDate = (returnDate) => {
  if (!returnDate) {
    return "Please provide a return date.";
  }
  if(returnDate){
      const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (!regex.test(returnDate)) {
        return "Please provide a valid return date , It accept only number.";
      }
  }
  return "";  
}
export const ValidateClasificationName = (clasifisction_name) => {
  if (!clasifisction_name) {
    return "Please provide a Clasification name.";
  }
  return "";
};
export const ValidateCampaignName = (campaign_name) => {
  if (!campaign_name) {
    return "Please provide a Campaign name.";
  }
  return "";
};

export const ValidateExpireDate = (expire_date) => {
  if (!expire_date) {
    return "Please provide a Expire Date.";
  }
  return "";
};

// export const ValidateSettingName = (key_name) => {
//   if (!key_name) {
//     return "Please provide a Key name.";
//   }
//   return "";
// };