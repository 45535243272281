import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Select, { components } from "react-select";
import { ClassificationDropDown, productClassificationSubmit, AllProducts, allClassification } from "../../service/apiService";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductClassificationCreateForm = () => {
  const [ClassificationLists, setClassification] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [ClassificationList, setClassificationList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [ClassificationFields, setClassificationFields] = useState([{ classification_id: null, dropdownValue: "" }]);
  const [ProductFields, setProductFields] = useState([{ product_id: null, dropdownValue: "" }]);

  useEffect(() => {
    ClassificationDropDownList();
    ProductDropDownList();
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };
      const responseData = await allClassification(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }
      setClassification(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDatas = async () => {
    try {
      const responseData = await AllProducts(token);
      let productData = Array.isArray(responseData) ? responseData : responseData.data || [];
      setProductList(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ClassificationDropDownList = async () => {
    try {
      const response = await ClassificationDropDown(token);
      setClassificationList(response.data);
    } catch (error) {
      console.error("Error fetching Classification list:", error);
    }
  };

  const ProductDropDownList = async () => {
    try {
      const response = await AllProducts(token);
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching Product list:", error);
    }
  };

  const handleChangeDropdown = (index, value) => {
    const updatedFields = [...ClassificationFields];
    updatedFields[index].dropdownValue = value.value;
    updatedFields[index].classification_id = parseInt(value.value, 10);
    setClassificationFields(updatedFields);
  };

  const handleChangeDropdowns = (index, value) => {
    const updatedFields = [...ProductFields];
    updatedFields[index].dropdownValue = value.value;
    updatedFields[index].product_id = parseInt(value.value, 10);
    setProductFields(updatedFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (ProductFields[0].dropdownValue !== "" || ClassificationFields[0].dropdownValue !== "") {
      const classification_id = ClassificationFields[0]?.classification_id || null;
      const product_id = ProductFields[0]?.product_id || null;
      console.log(classification_id)
      console.log(product_id);
      const payload = {
        classification_id,
        product_id
      };

      try {
        const response = await productClassificationSubmit(token, payload);
        console.log(response);
        if (response.status_code === 200) {
          toast.success(response.message);
          setToastMessage(response.message);
        } else if (response === 400) {
          toast.error("Section already mapped");
        }
      } catch (error) {
        console.error("Error submitting data:", error);
        toast.error("Submission failed");
      }

      setTimeout(() => {
        setToastMessage(null);
      }, 3000);
    } else {
      toast.error("Please select classification or enter value");
    }
  };

  const classificationOptions = ClassificationList?.map(item => ({
    value: item.classification_id,
    label: item.classification_name.toUpperCase()
  }));

  const productOptions = ProductList?.map(item => ({
    value: item.product_id,
    label: item.product_name.toUpperCase()
  }));

  const CustomOption = (props) => (
    <components.Option {...props}>
      <div className="custom-option">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </div>
    </components.Option>
  );

  return (
    <>
      <main id="main" className="main">
        <SideBar />
        <AdminHeader />
        <div className="pagetitle">
          <h1>Product Classification Create</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item">Product Classification</li>
              <li className="breadcrumb-item active">Create</li>
            </ol>
          </nav>
        </div>
        <br />
        <section className="section">
          <div className="row">
            <div className="col-lg-10">
              <div className="card mb-4">
                <div className="card-body">
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <>
                      {ProductFields?.map((Product, index) => (
                        <div key={index} className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <label className="form-label">Product Name</label>
                              <Select
                                id={`Product-dropdown-${index}`}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={productOptions?.find(option => option.value === Product.dropdownValue)}
                                onChange={(value) => handleChangeDropdowns(index, value)}
                                options={productOptions}
                                styles={{ control: (provided) => ({ ...provided, minHeight: '50px' }) }}
                                components={{ Option: CustomOption }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      {ClassificationFields.map((Classification, index) => (
                        <div key={index} className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <label className="form-label">Classification</label>
                              <Select
                                id={`Classification-dropdown-${index}`}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={classificationOptions?.find(option => option.value === Classification.dropdownValue)}
                                onChange={(value) => handleChangeDropdown(index, value)}
                                options={classificationOptions}
                                styles={{ control: (provided) => ({ ...provided, minHeight: '50px' }) }}
                                components={{ Option: CustomOption }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-md-3 pt-4">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{ width: '130px', height: '40px' }}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Create
                        </button>
                      </div>
                    </>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <AdminFooter />
      <ToastContainer />
    </>
  );
};

export default ProductClassificationCreateForm;
