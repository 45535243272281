

import axios from "axios";

const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;
const APP_URL = process.env.REACT_APP_API_BASE_URL;
const adminApiService = axios.create({
  baseURL: ADMIN_BASE_URL,
});

const userApiServices = axios.create({
  baseURL: APP_URL,
})
export const AdminSignIn = async (userData) => {
  try {
    const response = await adminApiService.post('/login', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCategoryList = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-categories', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const FaqcategorryDropdown = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await userApiServices.get('/faq-categories-dropdown', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const ChatbotcategorryDropdown = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-chatbot-categories', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const ChatbotEnquiryDropdown = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-chatbot-enquiries-dropdown', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};



export const GetFaqCategoryList = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-faq-categories', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const GetchatbotCategoryList = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-chatbot-categories', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const GetchatbotEnquiryList = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-chatbot-enquiries', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};




export const GetFaqList = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/get-all-faqs', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};



export const GetqustionList = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-chatbot-questions', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};




export const GetCampaignList = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-campaigns', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const GetPagesList = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-pages', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const GetClassificationList = async (token) => {
  if (token) {


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-classifications', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};




export const AdminLogout = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/logout', config);
      return response.data;
    } catch (error) {
      throw error;
    }

  }
};

export const fetchAdminData = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/current-admin', config);
      if (response.status !== 200) {
        throw new Error('Token is not valid');
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};
export const CategoriesDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/get-categories-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};
export const MediaList = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/media-list', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};
export const MediaImageUpload = async (token, product_id, product_images, listOrder, isVideo) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    const body = {
      product_images: product_images,
      product_id: product_id,
      list_order: listOrder,
      is_active: 1,
      is_video: isVideo

    }
    try {
      const response = await adminApiService.post('/product-image-upload', body, config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};

export const BrandDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get('/get-brands-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};

export const AllProducts = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/products-dropdown', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const allProducts = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-products', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const TaxDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get('/taxes-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};
export const DiscountDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get('/discounts-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};
export const VariantDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await adminApiService.get('/variant-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};



export const productVariantSubmit = async (token, payload, product_id) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    const body = {

      product_id: product_id,
      variants: payload,
      is_active: 1,

    }
    try {
      const response = await adminApiService.post('/create-product-variant', payload, config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};
export const productVariantUpdate = async (token, payload, product_id) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    const body = {

      product_id: product_id,
      variants: payload,
      is_active: 1,

    }
    try {
      const response = await adminApiService.post('/update-product-variant', payload, config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};
export const productEdit = async (token, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    }
    const response = await adminApiService.get(`/get-product/${id}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const productView = async (token, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    }
    const response = await adminApiService.get(`/get-product/${id}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteProduct = async (token, productId) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    const body = {
      product_id: productId
    };
    try {
      const response = await adminApiService.post('/delete-product', body, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}
export const productVariantEdit = async (token, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    }
    const response = await adminApiService.get(`/get-product-variant/${id}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const MultipleMediaUpload = async (token, payload) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.post('/multiple-media-upload', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}
export const MediaUpload = async (token, payload) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.post('/media-upload', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}
export const BannerDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get('/banners-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};
export const AdsDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get('/ads-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};
export const CouponDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get('/coupons-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};

export const SectionList = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get('/all-sections', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};
export const addSection = async (token, payload) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.post('/add-section', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}
export const pageSectionList = async (token, pageid) => {
  if (token) {
    console.log(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get(`/page-sections/${pageid}`, config);
      console.log(response);
      return response.data;
    } catch (error) {
      return 400;
    }
  } else {
    //console.log(1111111);
    return null;
  }
};

export const Getorderdetails = async (token, OrderId) => {
  if (token) {
    console.log(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get(`/get-order-detail/${OrderId}`, config);
      console.log(response);
      return response.data;
    } catch (error) {
      return 400;
    }
  } else {
    //console.log(1111111);
    return null;
  }
};


export const Getorderdetailsupdate = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.post('/update-order', payload, config);


    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}


export const ClassificationDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get('/classifications-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};



export const productClassificationSubmit = async (token, payload, product_id) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    const body = {

      product_id: product_id,
      classification_id: payload,


    }
    try {
      const response = await adminApiService.post('/create-classification-map', payload, config);

      return response.data;
    } catch (error) {

      return 400;
    }
  } else {
    return null;
  }
};
export const productClasssificationUpdate = async (token, payload, product_id) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    const body = {
      product_id: product_id,
      classification_id: payload,


    }
    try {
      const response = await adminApiService.post('/update-classification-map', payload, config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};

export const productClassificationEdits = async (token, productId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    }
    const response = await adminApiService.get(`/get-classification/${productId}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}


export const allClassification = async (token, productId) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get(`/product-classifications-map/${productId}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};

export const saveKanbanChangesAPICall = async (token, payload) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.post('/edit-section-place', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}
export const CampaignDropDown = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };

    try {
      const response = await adminApiService.get('/campaigns-dropdown', config);
      return response.data;
    } catch (error) {

      throw error;
    }
  } else {
    return null;
  }
};

export const AllCampaign = async (token, productId) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.get(`/product-campaign-map/${productId}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};
export const productCampaignEdits = async (token, productId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await adminApiService.get(`/get-campaign/${productId}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const productCampaignUpdate = async (token, payload, product_id) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      product_id: product_id,
      campaign_id: payload,
    }
    try {
      const response = await adminApiService.post('/update-campaign-map', payload, config);
      return response.data;

    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};
export const productCampaignSubmit = async (token, payload, product_id) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    const body = {

      product_id: product_id,
      campaign_id: payload,


    }
    try {
      const response = await adminApiService.post('/create-campaign-map', payload, config);

      return response.data;
    } catch (error) {

      return 400;
    }
  } else {
    return null;
  }
};


export const getSectionData = async (token, section_group, pageid) => {



  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
    };
    try {
      const response = await adminApiService.get(
        `/view-section-data/${section_group}/${pageid}`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching section data:", error.response ? error.response.data : error.message);
      throw error;
    }
  } else {
    console.error("Token is missing");
    return null;
  }
};

// UPDATE
export const updateSection = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
    };
    try {
      const response = await adminApiService.post(
        "/update-page-section",
        payload,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};

export const createBlogCategoriesAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.post('/create-blog-category', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}

export const getAllBlogCategoriesAPICall = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-blog-categories', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}


export const createBlogTagsAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.post('/create-blog-tag', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}

export const getAllBlogTagAPICall = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/all-blog-tags', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}

export const getBlogTagAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.get(`/get-blog-tag/${payload}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}

export const updateBlogTagAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.post('/update-blog-tag', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}

export const getAllBlogsAPICall = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.get('/get-all-blogs', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}

export const getAllBlogTagsAPICall = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await userApiServices.get('/blog-tags-dropdown', config);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  } else {
    return null;
  }
}

export const getBlogCategoryAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.get(`/get-blog-category/${payload}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}
export const getBlogAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.get(`/get-single-blog/${payload}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}


export const updateBlogCategoriesAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await adminApiService.post('/update-blog-category', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}

export const updateCampaignNameAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.post('/update-campaign-map', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}

export const createBlogAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.post('/create-blog', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}



export const createfaqcategory = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.post('/create-faq-category', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}


export const createFaq = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.post('/create-faq', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}


export const createEnquiry = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.post('/create-chatbot-enquiry', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}




export const updateBlogAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.post('/update-blog', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}

export const GetSettingList = async (token) => {


  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.get('/get-settings-data', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};



export const GetAllOrderList = async (token) => {


  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.get('/all-orders', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};



export const GetsubpageList = async (token) => {


  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await userApiServices.get('/get-sub-pages', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};


export const GetSubPagesList = async (token) => {
  console.log(token)
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.get('/get-all-sub-pages', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }

};

export const AppAboutUsApi = async () => {

  try {
    const response = await axios.get('https://folcon.pureinfant.in/api/test');
    return response.data;
  } catch (error) {
    throw error;

  }
};


export const deleteSection = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await adminApiService.post('/remove-section', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
}
export const verifyProductKey = async (productKeyToken) => {
  try {
    const productKey = {
      product_key: productKeyToken
    }
    console.log(productKey, "productKey444444");
    const response = await adminApiService.post('/app-key-verify', productKey)
    return response.data

  } catch (error) {
    throw (error)
  }
}




export default adminApiService;
