import React, { useState, useEffect } from "react";
import adminLogo from '../../../assets/images/admin-logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faMagnifyingGlass, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import adminprofile from '../../../assets/images/admin.jpg'
import { AdminLogout, fetchAdminData } from '../../../service/apiService';
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";





const AdminHeader = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(null);


  useEffect(() => {
    const getAdmin = async () => {
      const token = localStorage.getItem('admin_token');
      if (token) {
        try {
          const userData = await fetchAdminData(token);
          if (userData && userData.data && userData.data.full_name) {
            setAdmin(userData.data.full_name);
          }
        } catch (error) {
          navigate('/admin'); // Redirect to login page if there's an error fetching admin data
        }
      } else {
        navigate('/admin'); // Redirect to login page if token doesn't exist
      }
    };

    getAdmin(); // Call the function when the component mounts
  }, []);





  const adminLogout = async () => {
    const token = localStorage.getItem('admin_token');
    if (token) {
      const LogoutService = await AdminLogout(token);
      if (LogoutService.status_code === 200) {
        localStorage.removeItem('admin_token');
        setAdmin(null);
        navigate('/admin');
        toast.success(LogoutService.message);
      }
    }
  };



  return (

    <main className="main-wrap">
      <header id="header" className="header fixed-top d-flex align-items-center" style={{ zIndex: 100 }}>
        {/* <div className="d-flex align-items-center justify-content-between" style={{paddingLeft:'40px'}}>

          <img src={adminLogo} className="admin-logo-image"  alt ="admin"/>
            
        </div> */}

        <div className="search-bar" style={{ paddingLeft: '600px' }}>
          <form
            className="search-form d-flex align-items-center"
            method="POST"
            action="#"
          >

            <button type="submit" title="Search">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </form>
        </div>

        {/* End Search Bar */}
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item d-block d-lg-none">
              <a className="nav-link nav-icon search-bar-toggle " href="/">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </a>
            </li>

            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="/"
                data-bs-toggle="dropdown"
              >
                <img
                  src={adminprofile}
                  alt="Profile"
                  className="rounded-circle"
                />
                <span className="d-none d-md-block dropdown-toggle ps-2 name">
                  Admin
                </span>
              </a>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">


                <li>
                  <Link className="dropdown-item d-flex align-items-center" onClick={adminLogout}>
                    <FontAwesomeIcon icon={faRightFromBracket} />&nbsp;&nbsp;
                    <span>Sign Out</span>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item d-flex align-items-center" onClick={adminLogout}>
                    <FontAwesomeIcon icon={faUser} /> &nbsp;&nbsp;
                    <span>My Account </span>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item d-flex align-items-center" onClick={adminLogout}>
                    <FontAwesomeIcon icon={faGear} />&nbsp;&nbsp;
                    <span>Settings</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>

    </main>

  );


}

export default AdminHeader;