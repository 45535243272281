import React, { useEffect, useState, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faPlus, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { MediaImageUpload, MediaList } from "../../service/apiService";
import { toast } from "react-toastify";
import Modal from "react-modal";
import ImageGalary from '../../assets/images/video.jpg'
import { Link } from "react-router-dom";
import NoData from "../../assets/images/9264885-removebg.png"

const ProductVideoMedia = ({ productId, disabled }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const token = localStorage.getItem("admin_token");
  const AppUrl = "https://folcon.pureinfant.in/api/files/";
  const closeModal = () => setModalIsOpen(false);
  const openModal = () => setModalIsOpen(true);
  const ItemTypes = {
    VIDEO: "video",
  };
  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      //  if(response.length > 0){
      response.then((response) => {
        const videoUrls = response.data?.filter(url => url.endsWith('.mp4') || url.endsWith('.mov') || url.endsWith('.avi'));
        setVideoUrls(videoUrls);
      });
      //  }
    }
  }, [token]);
  const openalert = () => {
    toast.error("Please Fill The Product Form Data", {
    });
  };
  const moveVideo = useCallback(
    (fromIndex, toIndex) => {
      setSelectedVideos((prevVideos) => {
        const updatedVideos = [...prevVideos];
        const [movedVideo] = updatedVideos.splice(fromIndex, 1);
        updatedVideos.splice(toIndex, 0, movedVideo);
        return updatedVideos;
      });
    },
    []
  );

  const handleDeleteVideo = (index) => {
    if (selectedVideos.length > 0) {
      setSelectedVideos(selectedVideos?.filter((_, i) => i !== index));
    }

  };


  const handleUpdateVideos = async () => {
    const updatedImageUrls = selectedVideos?.map((index) => videoUrls[index]);
    const listOrderPayload = selectedVideos?.map((_, index) => index + 1);
    setSelectedVideos(updatedImageUrls);
    if (updatedImageUrls.length === 0) {
      toast.error("Please select at least one video");
      return;
    }
    const mediaList = await MediaImageUpload(
      token,
      productId,
      updatedImageUrls,
      listOrderPayload,
      1
    );
    if (mediaList.status_code === 200) {
      toast.success("Videos updated successfully");
      // setSelectedVideos([]);
      setModalIsOpen(false);
    }
  };

  const handleVideoCheckboxChange = (index) => {
    if (selectedVideos.includes(index)) {
      setSelectedVideos(selectedVideos?.filter((i) => i !== index));
    } else {
      setSelectedVideos([...selectedVideos, index]);
    }
  };

  const DraggableVideo = ({ index, videoUrl }) => {
    const [{ isDragging }, ref] = useDrag({
      type: ItemTypes.VIDEO,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: ItemTypes.VIDEO,
      hover: (item) => {
        if (item.index !== index) {
          moveVideo(item.index, index);
        }
      },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
      <div
        ref={(node) => ref(drop(node))}
        className="position-relative mr-3 mb-3"
        style={{ opacity }}
      >
        <video
          src={videoUrl}
          className="img-fluid"
          controls
          style={{
            width: "200px",
            height: "auto",
            cursor: "move",
            border: "1px dashed #000",
            padding: "5px",
            borderRadius: "10px",
          }}
        />
        <button
          className="btn btn-danger position-absolute top-0 end-0"
          style={{ zIndex: "10" }}
          onClick={() => handleDeleteVideo(index)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="container Image">
        <div className="card-body-image">


          {/* Blog Container */}
          <div className="blog-container">
            <h5><strong>Media Video
            </strong> </h5>
            <hr></hr>

            {/* First Row: Selected Vidos */}
            <div className=" container row selected-images-row">
              <div className="previewImages">
                {videoUrls?.length > 0 ? (
                  <div className="d-flex flex-wrap">
                    {videoUrls.map((item, i) => (
                      <div key={i} style={{ marginRight: '8px', }}>
                        <video
                          src={AppUrl + item}
                          className="img-fluid"
                          alt={`Uploaded Video ${i + 1}`}
                          style={{ width: "200px", height: "100px" }}
                          controls
                          muted
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="d-flex flex-column align-items-center">
                    <img src={NoData} width={200} height={200} className="mb-0" alt="No Data" />
                    <Link onClick={openModal}>Click To Add Videos</Link>
                    <br />
                  </div>
                )}
              </div>
            </div>
            <hr></hr>
            <DndProvider backend={HTML5Backend}>
              <div className="d-flex flex-wrap" style={{ textAlign: "50px" }}>
                {selectedVideos?.map((index, i) => (
                  <DraggableVideo
                    key={index}
                    index={i}
                    videoUrl={AppUrl + videoUrls[index]}
                  />
                ))}
              </div>
            </DndProvider>




            {/* Buttons */}
            {
              disabled ? (
                <>
                  <div className="d-flex justify-content-center">
                    <img
                      className="btn btn-secondary ml-2 btn-sm"
                      style={{ textAlign: "center" }}
                      src={ImageGalary}
                      width={"250px"}
                      height={"250px"}
                      readOnly
                      onClick={openalert}
                      alt="Imgesss"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        className="btn btn-primary ml-2 btn-sm"
                        style={{ textAlign: "center" }}
                        onClick={openModal}
                      >
                        <FontAwesomeIcon icon={faPlus} />&nbsp;
                        Add Video
                      </button>
                      &nbsp; &nbsp; &nbsp;
                      <button
                        className="btn btn-primary ml-2 btn-sm"
                        style={{ textAlign: "center" }}
                        onClick={handleUpdateVideos}
                      >
                        <FontAwesomeIcon icon={faCloudArrowUp} />&nbsp;
                        Update Video
                      </button>
                    </div>
                  </div>

                </>
              )
            }
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Gallery"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            maxWidth: "800px",
            maxHeight: "600px",
            overflowY: "auto",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <div className="container" style={{ width: "100%" }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: "inline-block", marginRight: "10px" }}>
                Video Gallery
              </h2>
            </div>
            <div className="col-auto">
              <button
                onClick={closeModal}
                style={{
                  fontSize: "30px",
                  marginTop: "0",
                  display: "flex",
                  justifyContent: "flex-end",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  marginBlockEnd: "10px",
                }}
              >
                <FontAwesomeIcon icon={faTimes} style={{ color: "#63E6BE" }} />
              </button>
            </div>
          </div>
          <div className="row">
            {videoUrls?.map((videoUrl, index) => (
              <div key={index} className="col-md-4 mb-4">
                <div
                  style={{
                    width: "200px",
                    height: "auto",
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    position: "relative",
                  }}
                  onClick={() => handleVideoCheckboxChange(index)}
                >
                  <video
                    src={AppUrl + videoUrl}
                    className="img-fluid"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                  <input
                    type="checkbox"
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      zIndex: "10",
                    }}
                    checked={selectedVideos.includes(index)}
                    onChange={() => handleVideoCheckboxChange(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductVideoMedia;
