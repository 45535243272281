import React, { useState } from "react";
import ProductForm from "../../../components/Product/ProductForm";
import ProductImageMedia from "../../../components/Product/ProductImageMedia";
import ProductVideoMedia from "../../../components/Product/ProductVideoMedia";
import ProductVariantsForm from "../../../components/Product/ProductVariantsForm";
import AdminFooter from "../Dashboard/AdminFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm, faRectangleList, faWindowRestore } from "@fortawesome/free-solid-svg-icons";


const ProductCreate = () => {
  const [activeTab, setActiveTab] = useState('form');
  const [isProductFormSuccess, setIsProductFormSuccess] = useState(false);
  const [productId, setProductId] = useState(null);

  const handleProductFormSuccess = (productId) => {
    if (productId) {
      setIsProductFormSuccess(true);
      setProductId(productId);
      setActiveTab('media');
    }
  };

  const openTab = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <main id="main" className="product-create__main">
        <div className="product-create__sidebar">
          <div className="product-create__tabs">
            <button
              className={`product-create__tab ${activeTab === 'form' ? 'product-create__tab--active' : ''}`}
              onClick={() => openTab('form')}
            >
              <FontAwesomeIcon icon={faRectangleList} />
              &nbsp; &nbsp;
              <strong >
                Product Form
              </strong>
            </button>
            <button
              className={`product-create__tab ${activeTab === 'media' ? 'product-create__tab--active' : ''}`}
              onClick={() => openTab('media')}
              disabled={!isProductFormSuccess}
            >
              <FontAwesomeIcon icon={faPhotoFilm} />
              &nbsp; &nbsp;
              <strong >
                Product Media
              </strong>

            </button>
            <button
              className={`product-create__tab ${activeTab === 'variants' ? 'product-create__tab--active' : ''}`}
              onClick={() => openTab('variants')}
              disabled={!isProductFormSuccess}
            >
              <FontAwesomeIcon icon={faWindowRestore} />
              &nbsp; &nbsp;
              <strong>
                Product Variants
              </strong>
            </button>
            <div className="product-create__slider" style={{ top: `${activeTab === 'form' ? '0' : activeTab === 'media' ? '60px' : '120px'}` }}></div>
          </div>
        </div>
        <section className="product-create__tab-content">
          <div className={`product-create__tab-pane ${activeTab === 'form' ? 'active' : ''}`}>
            <ProductForm onSuccess={handleProductFormSuccess} />
          </div>
          <div className={`product-create__tab-pane ${activeTab === 'media' ? 'active' : ''}`}>
            <ProductImageMedia productId={productId} disabled={!isProductFormSuccess} />
            <ProductVideoMedia productId={productId} disabled={!isProductFormSuccess} />
          </div>
          <div className={`product-create__tab-pane ${activeTab === 'variants' ? 'active' : ''}`}>
            <ProductVariantsForm productId={productId} disabled={!isProductFormSuccess} />
          </div>
        </section>
      </main>
      <AdminFooter />
    </>
  );
};

export default ProductCreate;
