import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../components/Input";
import Dropdown from "../../../components/Dropdown";
import { ClassificationDropDown, productClassificationSubmit, productClasssificationUpdate, productClassificationEdits, allClassification } from "../../../service/apiService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView } from "@fortawesome/free-solid-svg-icons";
import { CustomToast } from "../../../Helper/Helper";
import axios from "axios";


const ProductClassificationEditForm = ({ productId }) => {
  const [ClassificationLists, setClassification] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [ClassificationList, setClassificationList] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [ClassificationFields, setClassificationFields] = useState([{ classification_id: null, dropdownValue: "" }]);
  const [disabled, setDisabled] = useState(true);


  useEffect(() => {
    if (!productId) {
      console.error("productId is undefined");
      return;
    }

    setDisabled(!productId);
    if (productId) {
      // productClassificationEdit();
      ClassificationDropDownList();
    }
  }, [productId]);

  useEffect(() => {
    if (productId) {
      fetchData(productId);
    }
  }, [productId]);

  const fetchData = async (productId) => {
    try {
      const responseData = await allClassification(token, productId);
      let productData = [];


      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setClassification(responseData.data);
    } catch (error) {
      console.error("Error fetching product classifications:", error);
    }
  };




  const ClassificationDropDownList = async () => {
    try {
      const response = await ClassificationDropDown(token);
      setClassificationList(response.data);
    } catch (error) {
      console.error("Error fetching Classification list:", error);
    }
  };

  const productClassificationEdit = async () => {
    if (productId) {
      const response = null;
      //const response = await productClassificationEdits(token, productId);
      if (response) {
        const ClassificationArray = response.data.Classifications;
        if (ClassificationArray) {
          const mappedClassifications = ClassificationArray.map(Classification => ({
            Classification_id: Classification.classification_id,
            dropdownValue: Classification.Classification_id.toString(),

          }));
          setClassificationFields(mappedClassifications);
        }
      }
    }
  };

  const handleAddClassification = () => {
    setClassificationFields([...ClassificationFields, { classification_id: null, dropdownValue: "", textboxValue: "", isActive: true }]);
  };

  const handleToggleActive = (index) => {
    const updatedFields = [...ClassificationFields];
    updatedFields[index].isActive = !updatedFields[index].isActive;
    setClassificationFields(updatedFields);
  };

  const handleChangeDropdown = (index, value) => {
    const updatedFields = [...ClassificationFields];
    updatedFields[index].dropdownValue = value;
    setClassificationFields(updatedFields);
  };

  const handleChangeTextbox = (index, value) => {
    const updatedFields = [...ClassificationFields];
    updatedFields[index].textboxValue = value;
    setClassificationFields(updatedFields);
  };

  const handleSubmit = async (event) => {
    const Classification = ClassificationFields[event];
    event.preventDefault();
    if (ClassificationFields[0].dropdownValue !== "") {
      const payload = {
        product_id: productId,
        classification_id: parseInt(Classification.dropdownValue),

        //   Classifications: ClassificationFields.map((Classification) => ({
        //     classification_id: parseInt(Classification.dropdownValue),

        //   }))
      };

      const response = await productClasssificationUpdate(token, payload);
      if (response.status_code === 200) {
        toast.success(response.message);
        setToastMessage(response.message);
        //   setClassificationFields([{ Classification_id: null, dropdownValue: "", textboxValue: "", isActive: true }]);
        //   setClassificationList([]);
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);

        fetchData(productId);
      }

      if (response.status_code === 400) {
        toast.error(response.message);
        setToastMessage(response.message);

        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    }
    else {
      toast.error("Please select atleast one Classification");
    }
  };

  const handleCreateClassification = async (index) => {
    const Classification = ClassificationFields[index];
    if (Classification.dropdownValue !== "") {
      const payload = {
        product_id: productId,

        classification_id: parseInt(Classification.dropdownValue),


      };

      const response = await productClassificationSubmit(token, payload);
      if (response.status_code === 200) {
        // toast.success("Classification created successfully");
        setToastMessage("Classification created successfully");

        setTimeout(() => {
          setToastMessage(null);
        }, 3000);

        fetchData(productId);
      }
      if (response === 400) {
        //  toast.error("Alredy Exist");
        setToastMessage("Alredy Exist");

        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    } else {
      toast.error("Please select atleast one Classification");
    }
  };

  const scrollContainerStyle = {
    maxHeight: '400px', // Adjust the max height as needed
    overflowY: 'auto',
  };

  // const tableStyle = {
  //   width: '100%',
  //   height: '100px', // Adjust the min-width as needed
  // };

  return (
    <div className="container" style={{
      width: '890px', height: '700px'
    }}>

      {toastMessage && (
        <CustomToast message={toastMessage} onClose={() => setToastMessage(null)} />
      )}
      <div className="card-body">
        <br />
        <form className="row g-3" onSubmit={handleSubmit}>
          {disabled ? (
            <div className="col-md-12">
              <div className="row g-3 align-items-center">
                <div className="col-md-4">
                  <label className="form-label">Classification</label>
                  <Dropdown options={ClassificationList} disabled />
                </div>
              </div>
            </div>
          ) : (
            <>
              {ClassificationFields.map((Classification, index) => (
                <div key={index} className="col-md-12">
                  <div className="row g-3 align-items-center">
                    <div className="col-md-3">
                      <label className="form-label">Classification</label>
                      <select
                        id={`Classification-dropdown-${index}`}
                        className="form-select"
                        value={Classification.dropdownValue}
                        onChange={(e) => handleChangeDropdown(index, e.target.value)}
                      >
                        {!ClassificationList ? (
                          <option value="">No Data...</option>
                        ) : ClassificationList.length === 0 ? (
                          <option value="">No Classification found</option>
                        ) : (
                          <>
                            <option value="">Select Classification</option>
                            {ClassificationList.map((ClassificationItem) => (
                              <option key={ClassificationItem.classification_id} value={ClassificationItem.classification_id}>
                                {ClassificationItem.classification_name.toUpperCase()}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>


                    {Classification.classification_id === null && (
                      <div className="pt-2">
                        <label className="form-label"></label>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleCreateClassification(index)}
                        >
                          Create
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}

          {/* <div className="text-center mt-3">
              <button type="submit" className="btn btn-primary" disabled={disabled}>
                Submit
              </button>
            </div> */}
        </form>
        <br /><br />
        {/* <p>(In-progress)</p> */}
        <div style={scrollContainerStyle} >
          <table className="table border"  >
            <thead>
              <tr>
                <th> S.No </th>
                <th> Classfication Name </th>
                <th> Action </th>
              </tr>
            </thead>

            {ClassificationLists.map((item, indexs) => (
              <tbody>
                <tr key={indexs}>
                  <td>{indexs + 1}</td>
                  <td>{item.classification_name.toUpperCase()}</td>
                  <td>
                    <div className="text-end">
                      <div className="dropdown">
                        <a
                          href="#"
                          data-bs-toggle="dropdown"
                          className="btn btn-light rounded btn-sm font-sm"
                        >
                          <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">

                          Edit / Delete

                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
      <ToastContainer />
    </div >
  );
};



export default ProductClassificationEditForm;
