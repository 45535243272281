import React, { useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark, faPlus, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import ReactDataTable from '../../../../components/DataTable';
import ReactPaginate from 'react-paginate';
import { getAllBlogCategoriesAPICall, getBlogCategoryAPICall, updateBlogCategoriesAPICall } from "../../../../service/apiService";
import AdminFooter from "../../Dashboard/AdminFooter";
import AdminHeader from "../../Dashboard/AdminHeader";
import SideBar from "../../Dashboard/SideBar";
import { Column } from "react-virtualized";
import { DataTable } from "primereact/datatable";


const BlogCategoryList = () => {
    const [globalFilter, setGlobalFilter] = useState('');
    const [blogCategories, setBlogCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        is_active: 0,
        id: ''
    });
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 8;
    const pageCount = Math.ceil(blogCategories.length / itemsPerPage);
    const currentBlogCategories = blogCategories.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;
        setFormData({
            ...formData,
            is_active: activeValue
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    useEffect(() => {
        getAllBlogCategories();
    }, []);

    const getAllBlogCategories = async () => {
        try {
            const token = localStorage.getItem('admin_token');
            const response = await getAllBlogCategoriesAPICall(token);
            if (response) {
                setBlogCategories(response.data);
                // console.log('getAllBlogCategoriesAPICall', response.data);
            }
        } catch (error) {
            console.error('getAllBlogCategories', error);
        }
    };

    const handleEdit = async (blogCategoryId) => {
        console.log('handleEdit', blogCategoryId);
        try {
            const token = localStorage.getItem('admin_token');
            const response = await getBlogCategoryAPICall(token, blogCategoryId);
            const responseData = response.data;
            if (response.status_code === 200) {
                console.log('getBlogCategoryAPICall', responseData);
                setFormData({
                    name: responseData.name,
                    id: responseData.id,
                    is_active: responseData.is_active,
                });
                openEditModal();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);

    const openEditModal = () => {
        setEditModalIsOpen(true);
    };

    const closeEditModal = () => {
        setEditModalIsOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        try {
            if (!formData.name) {
                toast.error('Please Fill The Form Data');
                return;
            }
            const response = await updateBlogCategoriesAPICall(token, formData);
            if (response.status_code === 200) {
                toast.success('Blog categories Updated successfully!');
                getAllBlogCategories();
                setFormData({
                    name: '',
                    is_active: 0,
                    id: ''
                });
            } else {
                toast.error('Blog categories Updated failed. Please try again.');
            }
        } catch (error) {
            console.error('Error creating brand:', error);
            toast.error('Blog categories Updated failed. Please try again.');
        }
        closeEditModal();
    };

    const columns = [
        {
            field: 'id', header: 'ID', body: (rowData, index) => (
                <span>{index.rowIndex + 1}</span>
            ),
        },
        { field: 'name', header: 'Blog Category Name' },
        { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.id)}>Edit info</a>
                            {/* <a className="dropdown-item text-danger" style={{ cursor: 'pointer' }} onClick={() => handleDelete(rowData.id)}>Delete</a> */}
                        </div>
                    </div>
                </div>
            )
        }
    ];


    // const header = (
    //     <div className="table-header">
    //         <div>
    //             <h3>Blog categories</h3>
    //             <div>
    //                 <span className="p-input-icon-left">
    //                     <i className="pi pi-search" />
    //                     <InputText
    //                         type="search"
    //                         value={globalFilter}
    //                         onInput={(e) => setGlobalFilter(e.target.value)}
    //                         placeholder="search blog categories"
    //                     />
    //                 </span>
    //             </div>
    //         </div>
    //         <div className="create-category-button">
    //             <button onClick={() => navigate('/admin/blogs/blogCategory/create')} className="btn btn-primary">Create blog category</button>
    //         </div>
    //     </div>
    // );
    const filteredProducts = blogCategories?.filter((product) =>
        product.name?.toLowerCase().includes(globalFilter.toLowerCase())
    );

    const startItem = pageNumber * itemsPerPage + 1;
    const endItem = Math.min((pageNumber + 1) * itemsPerPage, filteredProducts.length);


    return (
        <div>
            <AdminHeader />
            <SideBar />
            <main className="main-wrap">
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Blog Category  List</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/dashboard">Dashboard</a>
                                </li>
                                <li className="breadcrumb-item">Blog</li>
                                <li className="breadcrumb-item active">Blog Category</li>
                                <li className="breadcrumb-item active">Blog Category List</li>
                            </ol>
                        </nav>
                    </div>
                    <br />
                    <div className="pagetitle">
                        <div>
                            <button onClick={() => navigate('/admin/blogs/blogCategory/create')} className="btn btn-primary">
                                <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;
                                Create Blog Category
                            </button>
                        </div>
                        <div className="search-bar">
                            <span className="p-input-icon-left">
                                <input
                                    type="search"
                                    value={globalFilter}
                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                    placeholder="Search..."
                                    className="custom-input"
                                />
                            </span>
                        </div>
                    </div>
                    {/* End Page Title */}
                    <section className="section">
                        <DataTable
                            value={currentBlogCategories}
                            globalFilter={globalFilter}
                            emptyMessage="No blog categories records found"
                            className="react-data-table"
                        >
                            {columns.map((col, i) => (
                                <Column key={i} field={col.field} header={col.header} body={col.body} />
                            ))}
                        </DataTable>

                        <div className="pagetitle">
                            <div className="results-info">
                                Showing {startItem} - {endItem} of {filteredProducts?.length} results
                            </div>
                            <ReactPaginate
                                previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
                                nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
                                breakLabel={'...'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </div>

                    </section>
                    <ToastContainer />
                    <Modal
                        isOpen={editModalIsOpen}
                        onRequestClose={closeEditModal}
                        contentLabel="Edit Brand Info"
                        style={{
                            overlay: {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                            content: {
                                position: 'relative',
                                backgroundColor: '#fff',
                                borderRadius: '10px',
                                padding: '20px',
                                // maxWidth: '80%',
                                // maxHeight: '80%',
                                overflowY: 'auto',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                            },
                        }}
                    >
                        <div className='container' style={{ width: '900px' }}>
                            <div className="row">
                                <div className="col">
                                    <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Brand</h2>
                                </div>
                                <div className="col-auto"></div>
                                <button onClick={closeEditModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
                                </button>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label htmlFor="name" className="form-label">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Type here"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="toggle" className="form-label">
                                            Status
                                        </label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="toggle"
                                                checked={formData.is_active === 1}
                                                onChange={handleToggleChange}
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </form>
                            </div>
                        </div>
                    </Modal>
                </main>
            </main>
            <AdminFooter />
        </div>


    );
}

export default BlogCategoryList;
