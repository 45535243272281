import React, { useState } from "react";
import { verifyProductKey } from "../../../service/apiService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const Access = ({ setProductKeyState }) => {
  const navigate = useNavigate();
  const [productKey, setProductKey] = useState("");
  const [error, setError] = useState("");

  const validateProductKey = () => {
    if (!productKey) {
      setError("Product key is required");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = async () => {
    localStorage.removeItem("productKeyState");
    if (!validateProductKey()) {
      return;
    }

    try {
      const response = await verifyProductKey(productKey);
      if (response.status === "success") {
        setProductKeyState(false);
        setProductKey("");
        toast.success("Product key verified successfully!");
        navigate("/admin");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleChange = (e) => {
    setProductKey(e.target.value);
    if (error) {
      setError("");
    }
  };

  return (
    <div className="container">
      <div className="d-flex flex-column">
        <label htmlFor="productKey" className="form-label fw-semibold">
          Product key
        </label>
        <input
          type="text"
          className="form-control-lg mb-3"
          id="productKey"
          value={productKey}
          onChange={handleChange}
        />
        {error && <div className="text-danger">{error}</div>}
      </div>
      <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
        Kick Off
      </button>
    </div>
  );
};

export default Access;
