import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../components/Input";
import Dropdown from "../../../components/Dropdown";
import { VariantDropDown, productVariantEdit, productVariantSubmit, productVariantUpdate } from "../../../service/apiService";
import { CustomToast } from "../../../Helper/Helper";

const ProductVariantEditForm = ({ productId }) => {
  const [toastMessage, setToastMessage] = useState(null);
  const [variantList, setVariantList] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [variantFields, setVariantFields] = useState([{ variant_id: null, dropdownValue: "", textboxValue: "", isActive: true }]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(!productId);
    if (productId) {
      productVarientEdit();
      VariantDropDownList();
    }
  }, [productId]);

  const VariantDropDownList = async () => {
    try {
      const response = await VariantDropDown(token);
      setVariantList(response.data);
    } catch (error) {
      console.error("Error fetching variant list:", error);
    }
  };

  const productVarientEdit = async () => {
    if (productId) {
      const response = await productVariantEdit(token, productId);
      const variantArray = response.data.variants;
      if (variantArray) {
        const mappedVariants = variantArray.map(variant => ({
          variant_id: variant.variant_id,
          dropdownValue: variant.variant_label_id.toString(),
          textboxValue: variant.value,
          isActive: variant.is_active === 1
        }));
        setVariantFields(mappedVariants);
      }
    }
  };

  const handleAddVariant = () => {
    setVariantFields([...variantFields, { variant_id: null, dropdownValue: "", textboxValue: "", isActive: true }]);
  };

  const handleToggleActive = (index) => {
    const updatedFields = [...variantFields];
    updatedFields[index].isActive = !updatedFields[index].isActive;
    setVariantFields(updatedFields);
  };

  const handleChangeDropdown = (index, value) => {
    const updatedFields = [...variantFields];
    updatedFields[index].dropdownValue = value;
    setVariantFields(updatedFields);
  };

  const handleChangeTextbox = (index, value) => {
    const updatedFields = [...variantFields];
    updatedFields[index].textboxValue = value;
    setVariantFields(updatedFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (variantFields[0].dropdownValue !== "" || variantFields[0].textboxValue !== "") {
      const payload = {
        product_id: productId,
        variants: variantFields.map((variant) => ({
          variant_id: variant.variant_id,
          variant_label_id: parseInt(variant.dropdownValue),
          value: variant.textboxValue,
          is_active: variant.isActive ? 1 : 0
        }))
      };

      const response = await productVariantUpdate(token, payload);
      if (response.status_code === 200) {
        toast.success(response.message);
        setToastMessage(response.message);
        //   setVariantFields([{ variant_id: null, dropdownValue: "", textboxValue: "", isActive: true }]);
        //   setVariantList([]);
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    }
    else {
      toast.error("Please select atleast one variant");
    }
  };

  const handleCreateVariant = async (index) => {
    const variant = variantFields[index];
    if (variant.dropdownValue !== "" && variant.textboxValue !== "") {
      const payload = {
        product_id: productId,
        variants: [{
          variant_label_id: parseInt(variant.dropdownValue),
          value: variant.textboxValue,
          is_active: variant.isActive ? 1 : 0
        }]
      };

      const response = await productVariantSubmit(token, payload);
      if (response.status_code === 200) {
        toast.success("Variant created successfully");
        setToastMessage("Variant created successfully");
        productVarientEdit();
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    } else {
      toast.error("Please select atleast one variant");
    }
  };

  return (
    <div className="container" style={{ width: '890px', height: ' 749px' }}>
      {toastMessage && (
        <CustomToast message={toastMessage} onClose={() => setToastMessage(null)} />
      )}

      <div className="card-body">
        <br />
        <form className="row g-3" onSubmit={handleSubmit}>
          {disabled ? (
            <div className="col-md-12">
              <div className="row g-3 align-items-center">
                <div className="col-md-4">
                  <label className="form-label">Variant</label>
                  <Dropdown options={variantList} disabled />
                </div>
              </div>
            </div>
          ) : (
            <>
              {variantFields.map((variant, index) => (
                <div key={index} className="col-md-12">
                  <div className="row g-3 align-items-center">
                    <div className="col-md-3">
                      <label className="form-label">Variant</label>
                      <select
                        id={`variant-dropdown-${index}`}
                        className="form-select"
                        value={variant.dropdownValue}
                        onChange={(e) => handleChangeDropdown(index, e.target.value)}
                      >
                        {!variantList ? (
                          <option value="">No Data...</option>
                        ) : variantList.length === 0 ? (
                          <option value="">No Variant found</option>
                        ) : (
                          <>
                            <option value="">Select Variant</option>
                            {variantList.map((variantItem) => (
                              <option key={variantItem.id} value={variantItem.id}>
                                {variantItem.name.toUpperCase()}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    <div className="container">
                      <label className="form-label">Variant Value</label>
                      <Input
                        value={variant.textboxValue}
                        onChange={(e) => handleChangeTextbox(index, e.target.value)}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Status</label>
                      &nbsp; &nbsp;&nbsp; &nbsp;
                      <button
                        type="button"
                        className={`btn ${variant.isActive ? "btn-success" : "btn-secondary"}`}
                        onClick={() => handleToggleActive(index)}
                      >
                        {variant.isActive ? "Active" : "Inactive"}
                      </button>
                    </div>
                    {variant.variant_id === null && (
                      <div className="col-md-3">
                        <label className="form-label"></label>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleCreateVariant(index)}
                        >
                          Create
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
          <div className="text-center mt-3">
            <button type="button" className="btn btn-primary" onClick={handleAddVariant}>
              +
            </button>
          </div>
          <div className="text-center mt-3">
            <button type="submit" className="btn btn-primary" disabled={disabled}>
              Submit
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductVariantEditForm;
