import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { validateAddress, validateEmail, validateFullName, validatePhoneNo } from "../../../validation/CustomValidation";
import { useNavigate } from "react-router-dom";


const SellerCreate = () => {
  const navigate = useNavigate();
  const [sellerformData, setSellerFormData] = useState({
    seller_name: '',
    address: '',
    email: '',
    phone_no: '',
    is_active: 1
  });
  const [validationErrors, setValidationErrors] = useState({
    seller_name: '',
    address: '',
    email: '',
    phone_no: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    const sellerNameError = validateFullName(sellerformData.seller_name);
    const emailError = validateEmail(sellerformData.email);
    const AddressError = validateAddress(sellerformData.address);
    const phoneNumberError = validatePhoneNo(sellerformData.phone_no);
    setValidationErrors({
      seller_name: sellerNameError,
      address: AddressError,
      email: emailError,
      phone_no: phoneNumberError,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    try {
      if (!sellerformData.seller_name || !sellerformData.address) {
        toast.error('Please Fill The Form Data');
        return;
      }

      const phone_no = parseInt(sellerformData.phone_no, 10);

      const createPayload = {
        ...sellerformData,
        phone_no: phone_no,
      };


      const createResponse = await axios.post('https://folcon.pureinfant.in/admin/create-seller', createPayload, config);

      if (createResponse.data.status === "success") {
        toast.success('Seller created successfully!');
        setSellerFormData({
          seller_name: '',
          address: '',
          email: '',
          phone_no: '',
          is_active: 1
        });
        navigate('/admin/seller');

      } else {
        toast.error('Seller creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating brand:', error);
      toast.error('Seller creation failed. Please try again.');
    }
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setSellerFormData({
      ...sellerformData,
      is_active: activeValue
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let transformedValue = value;
    if (name == 'seller_name') {
      transformedValue = value.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    }
    if (name == 'address') {
      transformedValue = value.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    }
    if (name == 'email') {
      transformedValue = value.toLowerCase().replace(/[^a-z0-9@._-]/g, '');

    }
    if (name == 'phone_no') {
      transformedValue = value.replace(/[^0-9]/g, '');

    }
    setSellerFormData({ ...sellerformData, [name]: transformedValue });
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Seller Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Seller</li>
            <li className="breadcrumb-item active">Data</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-6">
              <label htmlFor="seller_name" className="form-label">
                Seller Name
              </label>
              <input
                id="seller_name"
                name="seller_name"
                type="text"
                value={sellerformData.seller_name}
                placeholder="Type here"
                className="form-control"
                onChange={handleChange}
              />
              {validationErrors.seller_name && (
                <div className="text-danger">{validationErrors.seller_name}</div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                id="address"
                name="address"
                type="text"
                value={sellerformData.address}
                placeholder="Type here"
                className="form-control"
                onChange={handleChange}
              />
              {validationErrors.address && (
                <div className="text-danger">{validationErrors.address}</div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={sellerformData.email}
                placeholder="Type here"
                className="form-control"
                onChange={handleChange}
              />
              {validationErrors.email && (
                <div className="text-danger">{validationErrors.email}</div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone_no" className="form-label">
                Phone No
              </label>
              <input
                id="phone_no"
                name="phone_no"
                type="text"
                value={sellerformData.phone_no}
                placeholder="Type here"
                className="form-control"
                onChange={handleChange}
              />
              {validationErrors.phone_no && (
                <div className="text-danger">{validationErrors.phone_no}</div>
              )}
            </div>


            <div className="col-md-6">
              <label className="form-check-label" htmlFor="toggle">
                Status
              </label>
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="toggle" checked={sellerformData.is_active === 1} onChange={handleToggleChange} />
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default SellerCreate;
