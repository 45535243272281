import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Select, { components } from "react-select";
import { CampaignDropDown, productCampaignSubmit, AllProducts, AllCampaign } from "../../service/apiService";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const ProductCampaignCreateForm = () => {
  const [campaignLists, setCampaign] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [campaignList, setCampaignList] = useState([]);
  const [productList, setProductList] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [campaignFields, setCampaignFields] = useState([{ campaign_id: null, dropdownValue: "" }]);
  const [productFields, setProductFields] = useState([{ product_id: null, dropdownValue: "" }]);

  useEffect(() => {
    campaignDropDownList();
    productDropDownList();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const responseData = await AllCampaign(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setCampaign(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDatas = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const responseData = await AllProducts(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setCampaign(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const campaignDropDownList = async () => {
    try {
      const response = await CampaignDropDown(token);
      setCampaignList(response.data);
    } catch (error) {
      console.error("Error fetching campaign list:", error);
    }
  };

  const productDropDownList = async () => {
    try {
      const response = await AllProducts(token);
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching Product list:", error);
    }
  };

  const handleChangeDropdown = (index, value) => {
    const updatedFields = [...campaignFields];
    updatedFields[index].dropdownValue = value.value;
    updatedFields[index].campaign_id = parseInt(value.value, 10); // Convert to integer
    setCampaignFields(updatedFields);
  };

  const handleChangeDropdowns = (index, value) => {
    const updatedFields = [...productFields];
    updatedFields[index].dropdownValue = value.value;
    updatedFields[index].product_id = parseInt(value.value, 10); // Convert to integer
    setProductFields(updatedFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (productFields[0].dropdownValue !== "" || campaignFields[0].dropdownValue !== "") {
      const campaign_id = campaignFields[0]?.campaign_id || null;
      const product_id = productFields[0]?.product_id || null;

      const payload = {
        campaign_id,
        product_id,
      };

      const response = await productCampaignSubmit(token, payload);

      if (response.status_code === 200) {
        toast.success("Successful Campaign Created");
      } else if (response === 400) {
        toast.error("Already Exist")
      }
    } else {
      toast.error("Please select campaign or enter value");
    }
  };

  const campaignOptions = campaignList?.map((item) => ({
    value: item.campaign_id,
    label: item.campaign_name.toUpperCase(),
  }));

  const productOptions = productList?.map((item) => ({
    value: item.product_id,
    label: item.product_name.toUpperCase(),
  }));

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div className="custom-option">
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label>{props.label}</label>
        </div>
      </components.Option>
    );
  };

  return (
    <>
      <main id="main" className="main">
        <AdminHeader />
        <SideBar />
        <div className="pagetitle">
          <h1>Product Campaign Create</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item">Product Campaign</li>
              <li className="breadcrumb-item active">Create</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-10">
              <div className="card mb-4">
                <div className="card-body">
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <>
                      {productFields?.map((product, index) => (
                        <div key={index} className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <label className="form-label">Product Name</label>
                              <Select
                                id={`product-dropdown-${index}`}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={productOptions?.find((option) => option.value === product.dropdownValue)}
                                onChange={(value) => handleChangeDropdowns(index, value)}
                                options={productOptions}
                                styles={{ control: (provided) => ({ ...provided, minHeight: "50px" }) }}
                                components={{ Option: CustomOption }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      {campaignFields?.map((campaign, index) => (
                        <div key={index} className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <label className="form-label">Campaign</label>
                              <Select
                                id={`campaign-dropdown-${index}`}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={campaignOptions?.find((option) => option.value === campaign.dropdownValue)}
                                onChange={(value) => handleChangeDropdown(index, value)}
                                options={campaignOptions}
                                styles={{ control: (provided) => ({ ...provided, minHeight: "50px" }) }}
                                components={{ Option: CustomOption }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-md-3 pt-4">
                        <label className="form-label"></label>
                        <button type="submit" className="btn btn-primary" style={{ width: "130px", height: "40px" }}>
                          <FontAwesomeIcon icon={faPlus} /> Create
                        </button>
                      </div>
                    </>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AdminFooter />

      </main>
      <ToastContainer />
    </>

  );
};

export default ProductCampaignCreateForm;
