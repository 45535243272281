import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import ProductCreate from "../../pages/views/product/Productcreate";


const Product = () => {

    return (
        <div>

            <SideBar />
            <AdminHeader />
            {/* <div> */}
            <ProductCreate />
            {/* </div> */}
        </div>
    );

}

export default Product;