import React, { useState } from "react";
import AdminHeader from "../Dashboard/AdminHeader";
import AdminFooter from "../Dashboard/AdminFooter";
import SideBar from "../Dashboard/SideBar";

import MainForm from "./SectionCreate/MainForm";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import KanbanBoard from "./SectionList/SectionList";

const Section = () => {
  const [isFormSuccess, setIsFormSuccess] = useState(false);


  const handleFormSuccess = () => {
    setIsFormSuccess(true);
    setTimeout(() => {
      setIsFormSuccess(false);
    }, 3000);
  };

  return (
    <>
      <AdminHeader />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Section List</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/dashboard">Dashbord</a>
              </li>
              <li className="breadcrumb-item">Section</li>
              <li className="breadcrumb-item active">Data</li>
            </ol>
          </nav>
        </div>
        {/* <section className="section"
          style={{ cssText: 'width: 1300px !important; height: 700px !important; background-color: white !important;' }}>
          <div className="row">
            <div className="col-lg-6">
              <MainForm onSuccess={handleFormSuccess} />
            </div>
            <div className="col-lg-6">
              <DndProvider backend={HTML5Backend}>
                <KanbanBoard success={isFormSuccess} />
              </DndProvider>
            </div>
          </div>
        </section> */}
        <section
          className="section"
          style={{
            cssText: 'width: 1300px !important; height: 700px !important; background-color: white !important;'
          }}
        >
          <div className="row">
            <div className="col-lg-6">
              <MainForm onSuccess={handleFormSuccess} />
            </div>
            <div
              className="col-lg-6"
            >
              <DndProvider backend={HTML5Backend}>
                <KanbanBoard success={isFormSuccess} />
              </DndProvider>
            </div>
          </div>
        </section>

      </main >
      <AdminFooter />
    </>
  );
};

export default Section;
