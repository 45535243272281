import React, { useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faPlus, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import { getAllBlogsAPICall, getBlogAPICall } from "../../../../service/apiService";
import AdminFooter from "../../Dashboard/AdminFooter";
import AdminHeader from "../../Dashboard/AdminHeader";
import SideBar from "../../Dashboard/SideBar";
import noImage from "../../../../assets/images/noimage.jpg";
import BlogEdit from "./BLogEdit";
import { Spinner } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "react-virtualized";
const AppUrl = "https://folcon.pureinfant.in/api/files/";

const BlogList = () => {
    const [globalFilter, setGlobalFilter] = useState('');
    const [blogCategories, setBlogCategories] = useState([]);
    const [editBlogForm, setEditBlogForm] = useState(false);
    const [editFormData, setEditFormData] = useState([]);
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const itemsPerPage = 8;
    const pageCount = Math.ceil(blogCategories.length / itemsPerPage);
    const currentBlogCategories = blogCategories.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        getAllBlogs();
    }, []);

    const getAllBlogs = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('admin_token');
            const response = await getAllBlogsAPICall(token);
            if (response) {
                setBlogCategories(response.data);
                console.log('getAllBlogCategoriesAPICall', response.data);
            }
        } catch (error) {
            console.error('getAllBlogCategories', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async (blogId) => {
        try {
            const token = localStorage.getItem('admin_token');
            const response = await getBlogAPICall(token, blogId);
            const responseData = response.data;
            if (response.status_code === 200) {
                console.log('getBlogAPICall', responseData);
                setEditBlogForm(true);
                setEditFormData(responseData)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const columns = [
        {
            field: 'id', header: 'ID',
            body: (rowData, index) => (
                <span>{index.rowIndex + 1}</span>
            ),
        },
        { field: 'title', header: 'Blog Title' },
        { field: 'blog_category_name', header: 'Blog Category Name' },
        { field: 'blog_tag_name', header: 'Blog Tag Name' },
        // { field: 'message', header: 'Message' ,body: (rowData) => (
        //     console.log('rowData.rowData',rowData)
        // )},   
        {
            field: "thumnail_image",
            header: "Image",
            body: (rowData) => (
                <img
                    src={AppUrl + rowData.image_path || noImage}
                    width={70}
                    height={70}
                    alt="Product"
                />
            ),
        },
        // { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{console.log(rowData)}{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.blog_id)}>Edit info</a>
                            {/* <a className="dropdown-item text-danger" style={{ cursor: 'pointer' }} onClick={() => handleDelete(rowData.id)}>Delete</a> */}
                        </div>
                    </div>
                </div>
            )
        }
    ];



    const filteredProducts = blogCategories?.filter((product) =>
        product.title?.toLowerCase().includes(globalFilter.toLowerCase())
    );

    const startItem = pageNumber * itemsPerPage + 1;
    const endItem = Math.min((pageNumber + 1) * itemsPerPage, filteredProducts.length);


    return (
        <div>
            <AdminHeader />
            <SideBar />
            {!editBlogForm ?




                <main id="main" className={`main ${loading ? "loading" : ""}`}>
                    {loading && (
                        <div className="loading-overlay">
                            <Spinner animation="border" role="status" className="large-spinner">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )}
                    {!loading && (
                        <>
                            <div className="pagetitle">
                                <h1>Blog List</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Dashboard</a>
                                        </li>
                                        <li className="breadcrumb-item">Blogs</li>
                                        <li className="breadcrumb-item active">Blog</li>
                                        <li className="breadcrumb-item active">List</li>
                                    </ol>
                                </nav>
                            </div>
                            <br />
                            <div className="pagetitle">
                                <div>
                                    <button onClick={() => navigate('/admin/blogs/blogCategory/create')} className="btn btn-primary">
                                        <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;
                                        Create blog category
                                    </button>
                                </div>
                                <div className="search-bar">
                                    <span className="p-input-icon-left">
                                        <input
                                            type="search"
                                            value={globalFilter}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                            placeholder="Search..."
                                            className="custom-input"
                                        />
                                    </span>
                                </div>
                            </div>
                            {/* End Page Title */}
                            <section className="section">
                                {filteredProducts.length > 0 ? (
                                    <>
                                        <DataTable
                                            value={currentBlogCategories}
                                            globalFilter={globalFilter}
                                            emptyMessage="No Blogs records found"
                                            className="react-data-table"
                                        >
                                            {columns.map((col, i) => (
                                                <Column key={i} field={col.field} header={col.header} body={col.body} />
                                            ))}
                                        </DataTable>

                                        <div className="pagetitle">
                                            <div className="results-info">
                                                Showing {startItem} - {endItem} of {filteredProducts?.length} results
                                            </div>
                                            <ReactPaginate
                                                previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
                                                nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageChange}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </>

                                ) : (
                                    <div className="no-data-message">No data available</div>
                                )}

                            </section>
                            <ToastContainer />
                        </>

                    )}
                </main>
                :
                <BlogEdit editFormData={editFormData} setEditBlogForm={setEditBlogForm} getAllBlogs={getAllBlogs} />
            }
            <AdminFooter />
        </div>


    );
}

export default BlogList;
